import { Injectable } from '@angular/core';
import {
  camelCase, chain, get, groupBy, head, keys, last, omit, reduce, set, uniqBy
} from 'lodash';
import compact from 'lodash-es/compact';
import _map from 'lodash-es/map';
import { Observable } from 'rxjs';
import {
  map, take, tap
} from 'rxjs/operators';
import { AppState } from 'src/app/core/states/app/app.state';
import { Permission } from '~permissions/models/permission.model';
import { PermissionsState } from '~permissions/state/permissions.state';
import { SecuritySettingsAppDataService } from '~shared/services/app-services/apiSecuritySettingsAppController';

export const CAMELCASED_PERMISSION_KEYS = [
  'actionStatus',
  'edit',
  'rightNav',
  'status'
];
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private _userId: number;

  get facilityId(): number {
    return this.appState.get('facilityId');
  }

  get userId(): number {
    return this.appState.get('user')?.ID;
  }

  constructor(
    private appState: AppState,
    private state: PermissionsState,
    private securitySvc: SecuritySettingsAppDataService
  ) { }

  initPermissions(reload = false): Observable<any> {
    if (!reload && this.state.get('allPermissions') && this._userId === this.userId) {
      return this.state.permitEdit$.pipe(take(1));
    }

    return this.securitySvc.getSecuritySettingsForUser(this.userId, this.facilityId)
      .pipe(
        tap(allPermissions => this.state.set('allPermissions', allPermissions)),
        map(allPermissions => {
          this._userId = this.userId;
          return this.buildPermissionsObj(allPermissions);
        }),
        tap(() => this.getActivePermissions())
      );
  }

  isEnabled(permitUserId: number, permission: string[], isCategoryItem = false): boolean {
    permission = compact(permission);

    if (CAMELCASED_PERMISSION_KEYS.includes(permission?.[0])) {
      permission = _map(permission, camelCase);
    }

    let enabled = get(this.state.activePermissions, permission, false);

    if (isCategoryItem && !enabled) {
      let categoryPermission = permission;

      categoryPermission.pop();

      categoryPermission.push(last(categoryPermission));

      enabled = get(this.state.activePermissions, categoryPermission, false);
    }

    if (typeof enabled === 'object') {
      enabled = enabled?.all || (enabled.self && +this.userId === +permitUserId);
    }

    return enabled;
  }


  getActivePermissions(permitType?: string, varietyType?: string, statusId?: number, actionStatusId?: number): any {
    const allPermissions = this.state.get('permitEdit') || {};
    const activePermissions = this.state.get('activePermissions') || {};

    if (permitType) {
      activePermissions.edit = get(allPermissions, [
        'edit',
        permitType,
        varietyType
      ], {});

      activePermissions.status = get(allPermissions, [
        'status',
        permitType,
        varietyType
      ], {});

      activePermissions.permitIndex = this.state.get('permitIndex')?.permitType ?? {};

      if (statusId) {
        activePermissions.actionStatus = get(allPermissions, [
          'actionStatus',
          permitType,
          varietyType,
          statusId
        ], {});
        activePermissions.edit = get(activePermissions.edit, `statusPerms.${statusId}`, {});
      }

      if (actionStatusId) {
        activePermissions.edit = get(activePermissions.edit, `actionStatusPerms.${actionStatusId}`, {});
      }

      activePermissions.edit = omit(activePermissions.edit, [ 'statusPerms', 'actionStatusPerms' ]);
    }


    activePermissions.asset = this.state.get('asset');
    activePermissions.permitIndex = this.state.get('permitIndex');
    activePermissions.rightNav = this.state.get('rightNav');
    activePermissions.masterList = this.state.get('masterList');
    activePermissions.create = this.state.get('permitCreate');
    activePermissions.dataFilterGroup = this.state.get('dataFilterGroup');
    activePermissions.dashboard = this.state.get('dashboard');
    activePermissions.dashlet = this.state.get('dashlet');

    this.state.set('activePermissions', activePermissions);

    return allPermissions;
  }

  private buildPermissionsObj(permissions: Permission[]) {

    const {
      Asset,
      Dashboard,
      Dashlet,
      EditPermit,
      LeftNav,
      Index,
      PermitStatus,
      PermitStatusActionStatus,
      RightNav,
      Permit,
      Prefix = [],
      MasterList = [],
      SafeTKonnect,
      DataFilterGroup
    } = groupBy(permissions, ({ Setting_UniqueName }) => head(Setting_UniqueName.split('_')));

    const dataFilterGroup = chain(DataFilterGroup)
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms =>
        reduce(permitTypePerms, (permsObj, { Setting_UniqueName }: Permission) => {
          const permissionType = last(Setting_UniqueName.split('_'));

          permsObj[permissionType] = true;

          return permsObj;
        }, {})
      )
      .value();

    this.state.set('dataFilterGroup', dataFilterGroup);

    const permitIndex = chain(Index)
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms =>
        reduce(permitTypePerms, (permsObj, { Setting_UniqueName }: Permission) => {
          const permissionType = last(Setting_UniqueName.split('_'));

          permsObj[permissionType] = true;

          return permsObj;
        }, {})
      )
      .value();

    this.state.set('permitIndex', permitIndex);

    const permitCreate = chain(Permit)
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('Permit_', '')
      }))
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms =>
        chain(permitTypePerms)
          .groupBy('VarietyType')
          .mapValues(varietyTypePerms => this.groupByStatusId(varietyTypePerms))
          .value()
      )
      .value();

    this.state.set('permitCreate', permitCreate);

    const permitEditPermissions = chain(EditPermit)
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('EditPermit_', '')
      }))
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms =>
        chain(permitTypePerms)
          .groupBy('VarietyType')
          .mapValues(varietyTypePerms => this.groupByStatusId(varietyTypePerms))
          .value()
      )
      .value();

    const permitStatusPermissions = chain(PermitStatus)
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms =>
        chain(permitTypePerms)
          .groupBy('VarietyType')
          .mapValues(varietyTypePerms => reduce(varietyTypePerms, (permsObj, perms) => this.setPermitStatusPermission(permsObj, perms), {})
          )
          .value()
      )
      .value();

    const permitActionStatusPermissions = chain(PermitStatusActionStatus)
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms =>
        chain(permitTypePerms)
          .groupBy('VarietyType')
          .mapValues
          (varietyTypePerms => reduce(varietyTypePerms, (permsObj, perms) => this.setPermitStatusActionPermission(permsObj, perms), {}))
          .value()
      )
      .value();

    const permissionsObj = {
      edit: permitEditPermissions,
      status: permitStatusPermissions,
      actionStatus: permitActionStatusPermissions
    };

    this.state.set('permitEdit', permissionsObj);

    const safeTKonnect = groupBy(SafeTKonnect, 'Saved_ItemType');
    this.state.set('safetkonnect', safeTKonnect);

    Prefix.forEach(perm => {
      perm.Saved_Attribute = 'Prefix';
    });

    const masterListPermissions = chain(MasterList.concat(Prefix))
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('MasterList_', '')
      }))
      .groupBy('Saved_ItemType')
      .mapValues(permitTypePerms => {
        return chain(permitTypePerms)
          .groupBy('Saved_Attribute')
          .mapValues(() => true)
          .value(); }
      )
      .value();

    this.state.set('masterList', masterListPermissions);

    const assetPermissions = chain(Asset)
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('Asset_', '')
      }))
      .groupBy('Setting_UniqueName')
      .value();

    const assetPermObj = {};

    for (const key of keys(assetPermissions)) {
      const permPath = key.split('_').map(camelCase);
      let pathVal: any = uniqBy(assetPermissions[key], 'Saved_Attribute');
      if (permPath[0] === 'assetType') {
        pathVal = omit(pathVal.map(perm => perm.Saved_Attribute).reduce((perms, perm) => {
          perms[perm] = { all: true };
          return perms;
        }, {}), [ 'null' ]);
      } else {
        pathVal = pathVal.length ? { all: true } : { all: false };
      }

      set(assetPermObj, permPath, pathVal);
    }

    this.state.set('asset', assetPermObj);

    const dashboardPermissions = chain(Dashboard)
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('Dashboard_', '')
      }))
      .groupBy('Setting_UniqueName')
      .value();

    const dashboardPermObj = {};

    for (const key of keys(dashboardPermissions)) {
      const permPath = key.split('_').map(camelCase);
      let pathVal: any = uniqBy(dashboardPermissions[key], 'Saved_Attribute');

      pathVal = pathVal.length ? { all: true } : { all: false };

      set(dashboardPermObj, permPath, pathVal);
    }

    this.state.set('dashboard', dashboardPermObj);

    const dashletPermissions = chain(Dashlet)
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('Dashlet_', '')
      }))
      .groupBy('Setting_UniqueName')
      .value();

    const dashletPermObj = {};

    for (const key of keys(dashletPermissions)) {
      const permPath = key.split('_').map(camelCase);
      let pathVal: any = uniqBy(dashletPermissions[key], 'Saved_Attribute');

      pathVal = pathVal.length ? { all: true } : { all: false };

      set(dashletPermObj, permPath, pathVal);
    }

    this.state.set('dashlet', dashletPermObj);


    const rightNavPermissions = chain(RightNav)
      .map(({ Setting_UniqueName, ...perm }) => ({
        ...perm,
        Setting_UniqueName: Setting_UniqueName.replace('RightNav_', '')
      }))
      .groupBy('Setting_UniqueName')
      .value();

    const rightNavPermOb = {};

    for (const key of keys(rightNavPermissions)) {
      const permPath = key.split('_').map(camelCase);
      let pathVal: any = uniqBy(rightNavPermissions[key], 'Saved_Attribute');
      pathVal = pathVal.length ? { all: true } : { all: false };

      set(rightNavPermOb, permPath, pathVal);
    }

    this.state.set('rightNav', rightNavPermOb);

    return permissionsObj;
  }

  private groupByStatusId(permissions: Permission[]): any {
    /* FIXME: this is disgusting. I feel bad about it */
    return chain(permissions)
      .reduce((permsObj, perm) => {
        const statusId = perm.Saved_StatusID;

        if (statusId) {
          (permsObj.statusPerms || (permsObj.statusPerms = [])).push(perm);
        } else {
          permsObj = this.setPermitEditPermission(permsObj, perm);
        }

        return permsObj;
      }, {} as {[key: string]: any})
      .mapValues((permsNode, key) => {
        if (key === 'statusPerms') {
          permsNode = chain(permsNode)
            .groupBy('Saved_StatusID')
            .mapValues(perms =>
              chain(perms)
                .reduce((permsObj, perm) => {
                  const actionStatusId = perm.Saved_StatusActionStatusID;
                  if (actionStatusId) {
                    (permsObj.actionStatusPerms || (permsObj.actionStatusPerms = [])).push(perm);
                  } else {
                    permsObj = this.setPermitEditPermission(permsObj, perm);
                  }
                  return permsObj;
                }, {} as {[key: string]: any})
                // eslint-disable-next-line @typescript-eslint/no-shadow
                .mapValues((actionStatusPermsNode, key) => {
                  if (key === 'actionStatusPerms') {
                    actionStatusPermsNode = chain(actionStatusPermsNode)
                      .groupBy('Saved_StatusActionStatusID')
                      .mapValues(
                      // eslint-disable-next-line @typescript-eslint/no-shadow
                        actionStatusPerms => reduce(actionStatusPerms, (permsObj, perms) => this.setPermitEditPermission(permsObj, perms), {})
                      )
                      .value();
                  }

                  return actionStatusPermsNode;
                })
                .value()
            )
            .value();
        }

        return permsNode;
      })
      .value();
  }

  private setPermitEditPermission(permitEditPermissions: any, { Setting_UniqueName: uniqueName, ...permission }: Permission): any {
    let permissionPath = uniqueName.split('_');

    const isTab = permissionPath[0].startsWith('Tab');

    if (isTab) {
      permissionPath[0] = permissionPath[0].replace('Tab', '');
    }

    if (permissionPath.length <= 2 && ![ 'all', 'self' ].includes(last(permissionPath).toLowerCase())) {
      permissionPath = [ uniqueName ];
    }

    const settingName = permission.Saved_Attribute;
    const lastPathKey = last(permissionPath);

    if (settingName) {
      const permType = get(/(all|self)/i.exec(lastPathKey), 0);
      const nestedPermission = permission.SettingValue !== '1';

      if (permType) {
        const pathValues = [ settingName, permType ];

        if (nestedPermission) {
          pathValues.unshift(permission.SettingValue);
        }

        permissionPath.splice(2, 1, ...pathValues);
      } else {
        if (nestedPermission) {
          permissionPath.push(permission.SettingValue);
        }

        permissionPath.push(settingName);
      }
    }

    permissionPath = permissionPath.map(camelCase);

    return set(permitEditPermissions, permissionPath, true);
  }

  private setPermitStatusPermission(permitStatusPermissions: any, {
    Saved_Attribute, Setting_UniqueName
  }: Permission): any {
    const [ controlType, permissionType ] = Setting_UniqueName.split('_').slice(1);

    const permissionPath = [
      controlType.replace('Control', ''),
      Saved_Attribute,
      permissionType
    ].map(camelCase);

    return set(permitStatusPermissions, permissionPath, true);
  }

  private setPermitStatusActionPermission(
    permitStatusPermissions: any,
    {
      Saved_Attribute, Setting_UniqueName, Saved_StatusID, Saved_StatusActionID
    }: Permission
  ): any {
    const [ controlType, permissionType ] = Setting_UniqueName.split('_').slice(1);

    const permissionPath = [
      Saved_StatusID,
      Saved_StatusActionID,
      controlType.replace('Control', ''),
      Saved_Attribute,
      permissionType
    ].map(camelCase);

    return set(permitStatusPermissions, permissionPath, true);
  }


}
