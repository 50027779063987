import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../date-service/date.service';

@Pipe({
  name: 'displayDate'
})

export class DisplayDatePipe implements PipeTransform {
  constructor(
    private dateSvc: DateService
  ) {}

  transform(date: string): string {
    let formattedDate = this.dateSvc.convertDateForDisplay(date);
    if (formattedDate === 'Invalid DateTime') {
      formattedDate = date;
    }
    return formattedDate;
  }
}
