import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenuIconComponent } from './components/user-menu-icon/user-menu-icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizePipe } from '~shared/pipes/dom-sanitize.pipe';



@NgModule({
  declarations: [ UserMenuIconComponent ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    DomSanitizePipe
  ],
  exports: [ UserMenuIconComponent ]
})
export class UserMenuIconModule { }
