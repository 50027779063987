import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { environment } from 'src/environments/environment';
import { AppState } from '~core/states/app/app.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  loading$ = this.appState.loading$;

  constructor(
    private appState: AppState,
    private indexedDB: NgxIndexedDBService,
    private router: Router
  ) {
    if (environment?.APP_INSIGHTS_KEY?.length) {
      console.info('Application Insights key is set as:', environment.APP_INSIGHTS_KEY);

      const angularPlugin = new AngularPlugin();

      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.APP_INSIGHTS_KEY,
          extensions: [ angularPlugin ],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
          }
        }
      });

      appInsights.loadAppInsights();
    }
  }
}
