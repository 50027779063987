<div class="flex flex-col w-full h-full justify-start items-center">
  <div class="flex flex-row h-[25%] justify-center items-center">
    <div class="flex flex-col w-[10%] justify-center items-center">
      <img
        src="./assets/SafeTK-DragonSlayer-Logo.png"
        class="img img-fluid"
      />
    </div>
  </div>

  <div class="login-form p-5 flex flex-row h-[450px] justify-center items-center">
    <form
      [formGroup]="loginForm"
      class="w-[300px]"
      [ngClass]="{ 'animated shake': showInvalidCredsMsg }"
    >
      <div
        *ngIf="showInvalidCredsMsg && !loggedInViaSSO"
        class="form-group text-red-600 mb-1"
      >
        <p>Invalid Credentials. Please try again.</p>
      </div>

      <div
        *ngIf="loggedInViaSSO && (showInvalidCredsMsg || loggedOut)"
        class="form-group text-red-600 mb-1"
      >
        <p *ngIf="showInvalidCredsMsg">
          Invalid Credentials.
          <br />
          Login again or Start Over.
        </p>
        <p *ngIf="loggedOut">You have been logged out.</p>
        <button
          [disabled]="loading"
          (click)="startOver()"
          class="btn btn-primary btn-block"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Start Over
        </button>
      </div>

      <div class="form-group mb-1">
        <span *ngIf="connectionStringNames?.length > 1 && !(loggedInViaSSO && loggedOut)">
          <label
            [class.is-invalid]="submitted && connectionStringNameCtrl.errors"
            for="connectionStringName"
          >
            Connection String Name
          </label>
          <select
            formControlName="connectionStringName"
            placeholder="Loading..."
            class="form-control"
            [class.is-invalid]="submitted && connectionStringNameCtrl.errors"
          >
            <option
              *ngFor="let conn of connectionStringNames"
              [value]="conn"
            >
              {{ conn }}
            </option>
          </select>
          <div *ngIf="!connectionStringNames?.length">
            <mat-progress-bar
              color="primary"
              mode="indeterminate"
            ></mat-progress-bar>
          </div>
        </span>
        <div
          *ngIf="submitted && connectionStringNameCtrl.errors"
          class="invalid-feedback"
        >
          <div *ngIf="connectionStringNameCtrl.errors.required">Connection String Name is required</div>
        </div>
      </div>

      <div class="form-group mb-1">
        <span *ngIf="facilities?.length > 1 && !(loggedInViaSSO && loggedOut)">
          <label
            [class.is-invalid]="submitted && facilityIdCtrl.errors"
            for="facilityId"
          >
            Facility
          </label>
          <select
            formControlName="facilityId"
            placeholder="Loading..."
            class="form-control"
            [class.is-invalid]="submitted && facilityIdCtrl.errors"
          >
            <option
              *ngFor="let facility of facilities"
              [value]="facility.ID"
            >
              {{ facility.Name }}
            </option>
          </select>

          <div
            *ngIf="submitted && facilityIdCtrl.errors"
            class="invalid-feedback"
          >
            <div *ngIf="facilityIdCtrl.errors.required">Facility is required</div>
          </div>
        </span>
      </div>

      <div class="form-group mb-1">
        <span *ngIf="!(useSSO || stilLoggingIn || loggedInViaSSO)">
          <label
            [class.is-invalid]="submitted && usernameCtrl.errors"
            for="username"
          >
            Username
          </label>
          <input
            type="text"
            formControlName="username"
            class="form-control"
            [ngClass]="{ 'is-invalid': submitted && usernameCtrl.errors }"
          />
          <div
            *ngIf="submitted && usernameCtrl.errors"
            class="invalid-feedback"
          >
            <div *ngIf="usernameCtrl.errors.required">Username is required</div>
          </div>
        </span>
      </div>

      <div class="form-group mb-1">
        <span *ngIf="!(useSSO || stilLoggingIn || loggedInViaSSO)">
          <label
            for="password"
            [class.is-invalid]="submitted && passwordCtrl.errors"
          >
            Password
          </label>
          <input
            type="password"
            formControlName="password"
            class="form-control"
            [class.is-invalid]="submitted && passwordCtrl.errors"
          />
          <div
            *ngIf="submitted && passwordCtrl.errors"
            class="invalid-feedback"
          >
            <div *ngIf="passwordCtrl.errors.required">Password is required</div>
          </div>
        </span>
      </div>

      <div
        *ngIf="!(loggedInViaSSO && loggedOut)"
        class="form-group"
      >
        <button
          [disabled]="loading || (loginForm.touched && loginForm.invalid)"
          (click)="onSubmit()"
          class="btn btn-primary btn-block"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Login
        </button>
      </div>
    </form>
  </div>

  <div id="version">Build Version: {{ uiVersion }}</div>
</div>
