import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output 
} from '@angular/core';
import { Dictionary } from 'lodash';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavGroupComponent {

  @Input() group: Dictionary<any>;

  @Input() isActive: boolean;

  @Input() isOpen: boolean;

  @Output() groupClick = new EventEmitter<Dictionary<any>>();
}
