import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';
import { DateTime, Settings } from 'luxon';
import { FacilityState } from '~core/states/facility/facility.state';

import { TIMEZONE_IANA_MAP } from '../timezone-IANA-map.constant';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  private _dateFormat = 'MM/dd/yyyy hh:mm';

  get dateFormat(): string {
    return this._dateFormat;
  }

  set dateFormat(dateFormat: string) {
    const timezoneRegex = /Z{1,4}$/i;

    if (!timezoneRegex.test(dateFormat)) {
      dateFormat += ' ZZZZ';
    }

    if (this.dateFormat !== dateFormat) {
      this._dateFormat = dateFormat;
    }
  }

  constructor(
    private facilityState: FacilityState
  ) {}

  convertDateForDisplay(utcDate: string, format = this.dateFormat): string {
    return DateTime.fromISO(utcDate, { zone: 'UTC' }).toLocal()
      .toFormat(format);
  }

  convertUTCDateForPickerInput(date: string): string {
    const localDate = DateTime.fromISO(date, { zone: 'UTC' }).toLocal();

    const {
      year,
      month,
      day,
      hour,
      minutes,
      seconds
    } = this.getDateParts(localDate);

    return new Date(year, month - 1, day, hour, minutes, seconds).toISOString();
  }

  convertLocalToUTC(date: string): string {
    return DateTime.fromISO(date).toUTC()
      .toISO();
  }

  getDateParts(date: DateTime): Dictionary<number> {
    return {
      year: date.year,
      month: date.month,
      day: date.day,
      hour: date.hour,
      minutes: date.minute,
      seconds: date.second
    };
  }

  getJSDateParts(date: Date): Dictionary<number> {
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
      hour: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds()
    };
  }

  getNowForDateTimePicker(): string {
    const {
      year,
      month,
      day,
      hour,
      minutes,
      seconds
    } = this.getDateParts(DateTime.local());

    return new Date(year, month - 1, day, hour, minutes, seconds).toISOString();
  }

  getLocalForDateInput(): string {
    return DateTime.local().toFormat(`yyyy-MM-dd`);
  }

  getLocalUTCFromBrowserDate(date: Date): string {
    const {
      year,
      month,
      day,
      hour,
      minutes,
      seconds
    } = this.getJSDateParts(date);

    return DateTime.local(year, month + 1, day, hour, minutes, seconds).toUTC()
      .toISO();
  }

  getUTC(): string {
    return DateTime.utc().toISO();
  }

  init(): void {
    this.facilityState.generalSettings$
      .subscribe(({ dateTimeFormat, timeZone }) => {
        this.setDateFormats(TIMEZONE_IANA_MAP[timeZone], dateTimeFormat);
      });
  }

  setDateFormats(timezone, dateFormat = this.dateFormat): void {
    this.dateFormat = dateFormat;

    Settings.defaultZone = timezone;
  }
}

export function InitializeDateService(dateSvc: DateService): () => void {
  return () => dateSvc.init();
}
