import {
  Component, Inject, OnInit 
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';
import { AppState } from '~core/states/app/app.state';
import { STILUserDataService } from '~shared/services/apiSTILUserController';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: [ './password-reset-dialog.component.scss' ]
})
export class PasswordResetDialogComponent implements OnInit {

  passwordForm: UntypedFormGroup;

  showCurrentPassword = false;

  showPassword = false;

  showPasswordVerify = false;

  passwordControlHasValue: boolean;

  get passwordCtrl(): AbstractControl {
    return this.passwordForm.get('password');
  }

  get passwordVerifyCtrl(): AbstractControl {
    return this.passwordForm.get('passwordVerify');
  }

  get currentPassword(): AbstractControl {
    return this.passwordForm.get('currentPassword');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userSvc: STILUserDataService,
    private appState: AppState,
    private matDialogRef: MatDialogRef<PasswordResetDialogComponent>,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.passwordForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', [ Validators.required, passwordPattenValidator() ]),
      passwordVerify: new UntypedFormControl('')
    }, passwordsMatchValidator());
  }

  getInputType(show: boolean): string {
    return show ? 'text' : 'password';
  }

  resetPassword(): void {
    const facilityId = this.appState.get('facilityId');
    const user = this.appState.get('user');
    this.userSvc.isValidPassword(user.ID, this.passwordForm.controls['currentPassword'].value, facilityId).pipe(
      switchMap(() => this.userSvc.resetPassword(this.appState.get('user').ID, this.passwordCtrl.value) )
    )
      .subscribe(
        res => {
          this.toastr.success('Password successfully reset');
          this.matDialogRef.close();
        },
        err => this.toastr.error('Password reset failed. Confirm current Password.')
      );
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowPasswordVerify(): void {
    this.showPasswordVerify = !this.showPasswordVerify;
  }

  toggleShowCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

}

export function passwordPattenValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasLength = (value.length > 7) && (value.length < 36);
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const hasSpecialChar = /[^A-Za-z 0-9]/.test(value)
    const patternsValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar;

    const errors = {
      caseInvalid: !hasLowerCase || !hasUpperCase,
      numericInvalid: !hasNumeric,
      specialCharInvalid: !hasSpecialChar,
      lengthInvalid: !hasLength
    }

    return !patternsValid ? { patternsInvalid: errors } : null;
  }
}

export function passwordsMatchValidator(): ValidatorFn {
  return (group: UntypedFormGroup): ValidationErrors => {
    const password = group.controls['password'].value;
    const passwordVerify = group.controls['passwordVerify'].value;
    const invalid = password !== passwordVerify;

    return invalid ? { noMatch: true } : null;
  };
}
