import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentSettingsService {
  showSettings = new BehaviorSubject(false);
  $showSettings = this.showSettings.asObservable();

  constructor() { }

  toggleSettings() {
    this.showSettings.next(!this.showSettings.value);
  }
}
