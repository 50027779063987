import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { NgxStateModule } from '@devlime/ngx-state';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { PermitTypeIconPipe } from '~shared/pipes/permit-type-icon.pipe';
import { SharedModule } from '~shared/shared.module';

import { UserMenuIconModule } from '../../app/user-menu-icon/user-menu-icon.module';
import { dbConfig } from '../offline/constants/indexeddb-config.constant';
import { LayoutComponent } from './components/layout/layout.component';
import { NavGroupComponent } from './components/nav-group/nav-group.component';
import { NavLinkComponent } from './components/nav-link/nav-link.component';
import { RightNavComponent } from './components/right-nav/right-nav.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { WINDOW_PROVIDERS } from './services/window/window.service';

@NgModule({
  declarations: [
    SidebarComponent,
    RightNavComponent,
    LayoutComponent,
    TopbarComponent,
    NavGroupComponent,
    NavLinkComponent
  ],
  imports: [
    NgxIndexedDBModule.forRoot(dbConfig),
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    SharedModule,
    RouterModule,
    NgxStateModule,
    PermitTypeIconPipe,
    UserMenuIconModule
  ],
  providers: [ WINDOW_PROVIDERS ]
})
export class CoreModule {}
