import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
  NavigationEnd, NavigationStart, Router
} from '@angular/router';
import { withCache } from '@ngneat/cashew';
import {
  partition, reduce, remove
} from 'lodash';
import {
  forkJoin, Observable, Subject
} from 'rxjs';
import {
  filter, switchMap, tap
} from 'rxjs/operators';
import { User } from '~shared/models/user.model';

import { AppState } from '../states/app/app.state';
import { SidebarState } from '../states/sidebar/sidebar.state';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private dashboardRouted = new Subject();
  dashboardRouted$ = this.dashboardRouted.asObservable();

  get facilityId(): number {
    return this.appState.get('facilityId');
  }

  get userId(): number {
    return this.appState.get('user')?.ID;
  }

  constructor(
    private http: HttpClient,
    private appState: AppState,
    private router: Router,
    private state: SidebarState
  ) {}

  initSidebarState(): Observable<any> {
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe(() => this.appState.set('childPageTitle', null));

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        if (urlAfterRedirects.startsWith('/Permit')) {
          const permitType = urlAfterRedirects.split('/')?.[3];
          this.appState.set('activePermitType', permitType);
          this.state.set('activePermitGroup', this.appState.get('permitGroupIds')?.[permitType]);
        } else {
          this.appState.set('activePermitType', null);
          this.state.set('activePermitGroup', null);
        }

        if (urlAfterRedirects === '/') {
          this.appState.set('pageTitle', '');
          this.state.set('dashboardRouted');
        }
      });

    this.state.updateNavLinks$
      .pipe(
        switchMap(() => this.getSidebarSettings(this.facilityId, this.userId))
      )
      .subscribe(this.setSidebarObj.bind(this));

    return this.getNavLinks(this.facilityId, this.userId)
      .pipe(
        tap(([ sidebarSettings, rightNavSettings ]) => {
          this.setSidebarObj(sidebarSettings);
          this.setRightNavObj(rightNavSettings);
        })
      );
  }

  toggleSidebar() {
    this.state.set('collapsed', !this.state.get('collapsed'));
  }

  toggleRightNav() {
    this.state.set('rightNavCollapsed', !this.state.get('rightNavCollapsed'));
  }

  getRightNavSettings(facilityId: number, userId: number) {
    const params = new HttpParams({
      fromObject: {
        facilityId: `${facilityId}`,
        currentUserID: `${userId}`
      }
    });
    return this.http.get('dashboard/getsecuritysettings', { params });
  }

  setRightNavObj(dashboardSettings) {
    const rightNavEnabled = reduce(dashboardSettings, (navSections, setting) => {
      if (setting.UniqueName.includes('RightNav')) {
        const navSection = setting.UniqueName.split('_')[1];
        navSections[navSection] = true;
      }

      return navSections;
    }, {});

    this.state.set('rightNavEnabled', rightNavEnabled);
  }

  getSidebar(parentId: number) {
    const params: any = { parentId };
    return this.http.get(`sidebar`, {
      params,
      context: withCache()
    });
  }

  private getSidebarSettings(facilityId: number, userId: number) {
    const params = {
      FacilityId: `${facilityId}`,
      CurrentUserID: `${userId}`
    };

    return this.http.get(`sidebar/getfacilitysettings`, {
      params,
      context: withCache({ ttl: 5000 })
    });
  }

  setSidebarObj(sidebarSettings: any[]): void {
    const homeLink = remove(sidebarSettings, { PermitGroupID: 0 });
    const [ navGroups, navGroupLinks ] = partition(sidebarSettings, ({ ID }) => !ID.includes('-'));

    const navLinks = navGroups.map((group: any) => {
      if (group.PermitGroupID) {
        group.links = navGroupLinks.filter(x => x.PermitGroupID === group.PermitGroupID) ?? [];
      } else {
        // CMMS GROUPS
        group.links = navGroupLinks.filter(({ ID }) => ID.startsWith(group.ID)) ?? [];
      }

      return group;
    });

    const permitGroupIds = reduce(navGroups, (permitGroupObj, { ItemType, PermitGroupID }) => {
      permitGroupObj[ItemType] = PermitGroupID;

      return permitGroupObj;
    }, {});

    const permitTypeIcons = reduce(navGroups, (permitGroupObj, { ItemType, Icon }) => {
      permitGroupObj[ItemType] = Icon;

      return permitGroupObj;
    }, {});

    this.appState.set('permitTypeIcons', permitTypeIcons);
    this.appState.set('permitGroupIds', permitGroupIds);
    this.state.set('navLinks', navLinks);
  }

  getNavLinks(facilityId: number, userId: number): Observable<any[]> {
    return forkJoin([ this.getSidebarSettings(facilityId, userId), this.getRightNavSettings(facilityId, userId) ]);
  }

  setNavLinks(facilityId: number, { ID }: User): void {
    this.getNavLinks(facilityId, ID)
      .subscribe(([ sidebarSettings, rightNavSettings ]) => {
        this.setSidebarObj(sidebarSettings);
        this.setRightNavObj(rightNavSettings);
      });
  }
}

export function InitializeSidebar(injector: Injector): () => void {
  return () => {
    const sidebarSvc = injector.get(SidebarService);
    sidebarSvc.initSidebarState();
  };
}
