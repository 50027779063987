<div class="flex flex-col h-full w-full justify-start items-center">
  <div class="flex flex-row h-[25%] justify-center items-center">
    <div class="flex flex-col w-[10%] justify-center items-center">
      <img
        src="./assets/SafeTK-DragonSlayer-Logo.png"
        class="img img-fluid"
      />
    </div>
  </div>

  <div class="login-form p-5 flex flex-row h-[425px] justify-center items-center">
    <form
      [formGroup]="loginForm"
      class="w-[300px]"
      [ngClass]="{ 'animated shake': showInvalidCredsMsg }"
    >
      <div
        *ngIf="showInvalidCredsMsg"
        class="form-group mb-1"
        style="color: red"
      >
        <p>Invalid Credentials. Please try again.</p>
      </div>

      <div class="form-group mb-1">
        <span [hidden]="connectionStringNames?.length <= 1">
          <label
            [class.is-invalid]="submitted && connectionStringNameCtrl.errors"
            for="connectionStringName"
          >
            Connection String Name
          </label>
          <select
            formControlName="connectionStringName"
            placeholder="Loading..."
            class="form-control"
            [class.is-invalid]="submitted && connectionStringNameCtrl.errors"
          >
            <option
              *ngFor="let conn of connectionStringNames"
              [value]="conn"
            >
              {{ conn }}
            </option>
          </select>
          <div *ngIf="!connectionStringNames?.length">
            <mat-progress-bar
              color="primary"
              mode="indeterminate"
            ></mat-progress-bar>
          </div>
        </span>
        <div
          *ngIf="submitted && connectionStringNameCtrl.errors"
          class="invalid-feedback"
        >
          <div *ngIf="connectionStringNameCtrl.errors.required">Connection String Name is required</div>
        </div>
      </div>

      <div class="form-group mb-1">
        <label
          [class.is-invalid]="submitted && facilityIdCtrl.errors"
          for="facilityId"
        >
          Facility
        </label>
        <select
          formControlName="facilityId"
          placeholder="Loading..."
          class="form-control"
          [class.is-invalid]="submitted && facilityIdCtrl.errors"
        >
          <option
            *ngFor="let facility of facilities"
            [value]="facility.ID"
          >
            {{ facility.Name }}
          </option>
        </select>

        <div
          *ngIf="submitted && facilityIdCtrl.errors"
          class="invalid-feedback"
        >
          <div *ngIf="facilityIdCtrl.errors.required">Facility is required</div>
        </div>
      </div>

      <div class="form-group">
        <button
          [disabled]="loading || (loginForm.touched && loginForm.invalid)"
          (click)="onSubmit()"
          class="btn btn-primary btn-block"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Login
        </button>
      </div>
    </form>
  </div>

  <div id="version">Build Version: {{ uiVersion }}</div>
  <div id="version">Referer: {{ urlReferer }}</div>
</div>
