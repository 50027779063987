import { Injectable } from '@angular/core';
import { State, StateBehavior } from '@devlime/ngx-state';
import { Observable } from 'rxjs';

export interface NetworkStateData {
  readonly isOnline: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NetworkState extends State<NetworkStateData> {

  @StateBehavior<NetworkState, boolean>(false)
  private isOnline: boolean;
  readonly isOnline$: Observable<boolean>;

  constructor() {
    super();
  }
}
