import { Injectable, Inject } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve, Router, RouterModule
} from '@angular/router';
import { HttpCacheManager } from '@ngneat/cashew';
import { HotToastService } from '@ngneat/hot-toast';
import { find } from 'lodash';
import { AppState } from '~core/states/app/app.state';
import { STILFacilityDataService } from '~shared/services/apiSTILFacilityController';
import { STILGlobalDataDataService } from '~shared/services/apiSTILGlobalDataController';
import { SAMLDataService } from '~shared/services/apiSAMLController';
import { environment } from 'src/environments/environment'
import { WINDOW } from '~core/services/window/window.service';
import { AuthState } from '~auth/states/auth.state';

export interface LoginPageData {
  connectionStringNames: string[];
  defaultConnectionStringName: string;
  facilities: any[];
  defaultFacility: any[];
  idpSettings: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginPageResolve implements Resolve<Promise<LoginPageData>> {

  constructor(
    private appState: AppState,
    private state: AuthState,
    private stilGlobalDataSvc: STILGlobalDataDataService,
    private facilityDataSvc: STILFacilityDataService,
    private samlDataService: SAMLDataService,
    private cacheManager: HttpCacheManager,
    private toast: HotToastService,
    @Inject(WINDOW) private window: Window
  ) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<LoginPageData> {

    const idpSettings = await this.samlDataService.getSettings().toPromise();

    if(idpSettings.STILUseSSO && (route.queryParams?.flag === "SSOSafeTKPro") && !route.queryParams?.Token?.length && !this.state.get('loggedOut'))
    {
      this.window.location.href = idpSettings.SPAPIRedirect + "?flag=" + route.queryParams.flag;
      return;
    }

    if(idpSettings.UseSSO && (route.queryParams?.flag !== "SafeTKPro") && !route.queryParams?.Token?.length && !this.state.get('loggedOut'))
    {
      if(route.queryParams?.flag?.length)
      {
        this.window.location.href = idpSettings.SPAPIRedirect + "?flag=" + route.queryParams.flag;
        return;
      }
      else
      {
        this.window.location.href = idpSettings.SPAPIRedirect;
      }
    }

    this.appState.set('loading', true);
    /* Get connection strings */

    let connectionStringNamesList = await this.stilGlobalDataSvc.getConnectionStringList().toPromise();
    let connectionStringNames = [];

    for (const conn of connectionStringNamesList)
    {
      let facilityList = [];
      if((idpSettings.UseSSO || idpSettings.STILUseSSO) && (route.queryParams?.flag !== "SafeTKPro") && route.queryParams?.Token?.length)
      {
        facilityList = await this.facilityDataSvc.getActiveFacilitiesForSSOUser(route.queryParams.Token, conn).toPromise();
      }else {
        facilityList = await this.facilityDataSvc.getRecords(true, conn).toPromise();
      }
      if(facilityList.length)
      {
        connectionStringNames.push(conn);
      }
    }

    if (!connectionStringNames.length) {
      this.toast.error('No ConnectionString names were found. There must be an issue.');
    }

    this.cacheManager.delete('permit-facility-settings');
    this.cacheManager.delete('facility-settings');

    const connectionStringName = localStorage.getItem('lastConnectionStringName');
    const defaultConnectionStringName = find(connectionStringNames, name => name === connectionStringName) || connectionStringNames[0];



    /* Get facilities */
    let facilities = [];

    if(defaultConnectionStringName)
    {
      if((idpSettings.UseSSO || idpSettings.STILUseSSO) && (route.queryParams?.flag !== "SafeTKPro") && route.queryParams?.Token?.length)
      {
        facilities = await this.facilityDataSvc.getActiveFacilitiesForSSOUser(route.queryParams.Token, defaultConnectionStringName).toPromise();
      } else {
        facilities = await this.facilityDataSvc.getRecords(true, defaultConnectionStringName).toPromise();
      }

      if (!facilities.length) {
        this.toast.error('No Facilities were found for the default connection string name. Please try another connection string');

      }
    }
    const facilityId = sessionStorage.getItem('facilityId') || localStorage.getItem('lastFacilityId');

    const defaultFacility = find(facilities, { ID: +facilityId }) ?? facilities[0];

    this.appState.set('loading', false);


    if(!defaultConnectionStringName && (idpSettings.UseSSO || idpSettings.STILUseSSO))
    {
      this.state.set('loggedOut', true);
      this.appState.set('loggedInViaSSO', true);
    }

    return ({
      connectionStringNames,
      defaultConnectionStringName,
      defaultFacility,
      facilities,
      idpSettings
    });
  }
}
