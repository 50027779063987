<div
  class="flex flex-col"
  style="padding: 5px"
>
  <div class="flex flex-row h-full">
    <mat-form-field>
      <mat-label>Languages</mat-label>
      <mat-select
        [(ngModel)]="selectedLanguage"
        name="languages"
        (selectionChange)="onSelectionChange($event)"
      >
        <mat-option
          *ngFor="let language of languages"
          [value]="language"
        >
          {{ language.Name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      [matTooltip]="translateBasePath + '.Tooltip.Initialize' | translate"
      matTooltipPosition="right"
      mat-icon-button
      (click)="initializeTranslations()"
    >
      <mat-icon>psychology</mat-icon>
    </button>
  </div>

  <div class="flex flex-row h-full">
    <mat-tab-group class="w-full">
      <mat-tab label="Translations">
        <app-data-grid
          [rows]="translations"
          [useCRUDEvents]="true"
          [canEdit]="true"
          [canCreate]="true"
          [canDelete]="true"
          [CRUDConfig]="settingCRUD"
          [columns]="columns"
          [autoColumn]="false"
          [pageSize]="18"
          [loading]="translations.length === 0 && isLoading"
          (editSaved)="editRow($event)"
          (createSaved)="createRow($event)"
          (deleteClicked)="deleteRow($event)"
        ></app-data-grid>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
