import 'signalr';

import { Injectable, Injector } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthState } from '~auth/states/auth.state';
import { NetworkState } from '~auth/states/network-state/network.state';
import { AppState } from '~core/states/app/app.state';

declare var $: any;

export interface KickedOutEvent {
  kickedOutBy: string;
  permitId: number;
  permitType: string;
  permitPrefix: string;
  permitLogSerial: string;
  message?: string;
}
export interface BroadcastEvent {
  message: string;
}
@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private reconnectTried = false;
  private connection: any;
  private kickedOut = new Subject<KickedOutEvent>();
  private messageBroadcaster = new Subject<BroadcastEvent>();

  kickedOut$ = this.kickedOut.asObservable();
  messageBroadcaster$ = this.messageBroadcaster.asObservable();

  ownedPermitHub: any;

  /**
   * Need to get HotToastService from injector rather than constructor injection to avoid cyclic dependency error
   * @returns {}
   */
  private get toast(): HotToastService {
    return this.injector.get(HotToastService);
  }

  constructor(
    private appState: AppState,
    private authState: AuthState,
    private injector: Injector,
    private networkState: NetworkState
  ) {}

  init(): void {
    this.authState.isLoggedIn$
      .pipe(
        map(isloggedIn => !!isloggedIn)
      )
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.appState.set('serverConnectionStatus', 'reconnecting');
          this.connect();
        } else {
          this.disconnect();
        }
      });
  }

  connect() {
    if (!this.connection && this.networkState.get('isOnline')) {
      this.connection = $.hubConnection(environment?.SIGNALR_URL ?? `${environment.API_URL}signalr`);

      if (!environment?.production) {
        (this.connection.hub ??= {}).logging = true;
        this.connection.logging = true;
      }

      this.ownedPermitHub = this.connection.createHubProxy('ownedpermithub');
      // Event handlers for API calling into Client
      this.ownedPermitHub.on('triggerKickedOut', (event: KickedOutEvent) => this.kickedOut.next(event));
      this.ownedPermitHub.on('triggerToastToUI', (message: string) => this.toastToUI(message));
      this.ownedPermitHub.on('triggerConsoleToUI', (message: string) => this.consoleToUI(message));

      this.connection.error(error => {
        console.error('connection error:', error);
        this.appState.set('serverConnectionStatus', 'error');

        if (!this.reconnectTried) {
          console.info('Connect failed. Trying to reconnect...');
          this.reconnect();
        }
      });

      this.connection.connectionSlow(() => {
        this.appState.set('serverConnectionStatus', 'slow');
        console.warn('We are currently experiencing difficulties with the network connection.');
      });

      this.connection.disconnected(() => {
        this.appState.set('serverConnectionStatus', 'disconnected');
        console.warn('Connection disconnected.');

        if (!this.reconnectTried && this.authState.get('isLoggedIn')) {
          console.warn('Trying to reconnect...');
          this.reconnect();
        }
      });

      this.connection.start()
        .done((data: any) => {
          console.info('Connected to Notification Hub');
          this.appState.set('serverConnectionStatus', 'connected');
          this.reconnectTried = false; // Reset
        })
        .catch((error: any) => {
          console.error('Notification Hub error -> ' + error);
          this.appState.set('serverConnectionStatus', 'error');
        });
    }
  }

  disconnect() {
    if (this.connection && this.networkState.get('isOnline')) {
      this.connection.stop();
      this.connection = null;
      this.appState.set('serverConnectionStatus', 'disconnected');
    }
  }

  kickOut(event: KickedOutEvent): void {
    // Call API hub KickedOut method
    this.ownedPermitHub.invoke('KickedOut', event);
  }

  broadcastMessage(event: BroadcastEvent): void {
    // Call API hub MessageBroadcaster method
    this.ownedPermitHub.invoke('MessageBroadcaster', event);
  }

  consoleMessageToAll(event: BroadcastEvent): void {
    // Call API hub ConsoleBroadcaster method
    this.ownedPermitHub.invoke('ConsoleBroadcaster', event);
  }

  consoleToUI(message: string): void {
    if (this.appState.get('serverConnectionStatus') === 'connected') {
      this.appState.set('serverConnectionStatus', 'blink');
      setTimeout(() => this.appState.set('serverConnectionStatus', 'connected'), 200);
    }
    console.log(message);
  }

  toastToUI(message: string): void {
    if (this.appState.get('serverConnectionStatus') === 'connected') {
      this.appState.set('serverConnectionStatus', 'blink');
      setTimeout(() => this.appState.set('serverConnectionStatus', 'connected'), 200);
    }
    console.log(message);
    this.toast.show(message);
  }

  reconnect(): void {
    this.appState.set('serverConnectionStatus', 'reconnecting');
    this.reconnectTried = true;
    this.disconnect();
    this.connect();
  }
}

export function connectToSignalR(signalrSvc: SignalrService): () => void {
  return () => signalrSvc.init();
}
