<div class="modal-header">
  <h5 class="modal-title pull-left">Table Settings</h5>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body flex flex-row flex-wrap">
  <div
    *ngFor="let col of allColumns"
    class="checkbox w-1/3"
  >
    <input
      class="p-1"
      type="checkbox"
      [id]="col.name"
      (click)="toggle(col)"
      [checked]="isChecked(col)"
    />
    <label class="m-1">{{ col.prop }}</label>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="bsModalRef.hide()"
  >
    Save
  </button>
</div>
