import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output
} from '@angular/core';
import {
  cloneDeep, get, reject
} from 'lodash';


const BOOLEAN = 'boolean';
@Component({
  selector: 'app-datagrid-reorder-overlay',
  templateUrl: './datagrid-reorder-overlay.component.html',
  styleUrls: [ './datagrid-reorder-overlay.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatagridReorderOverlayComponent implements OnInit, OnChanges {

  @Input() referenceProp = 'Name';
  @Input() table;
  @Input() rows;
  @Input() height = 0;
  @Input() tableName;
  @Input() displayColumns;
  @Input() reorderConfig;
  @Input() reorderType;
  @Output() orderChange = new EventEmitter();
  x = 0;
  y = 0;
  width = 0;

  tableCopy: any = {};

  constructor() { }

  ngOnInit() {
    this.setOverlayPosition();
  }

  ngOnChanges() {
    this.setOverlayPosition();
    this.displayColumns = reject(this.displayColumns, ({ config }) =>
      get(config, 'showOnRestorationOnly') && this.reorderType !== 'RestorationTagNumber'
    );
    this.reorderConfig = cloneDeep(this.reorderConfig);
  }

  setOverlayPosition() {
    this.x = this.table.element.offsetParent.offsetLeft + 8;
    this.y = this.table.element.offsetParent.offsetTop + 85;
    this.width = parseInt(this.table.bodyComponent.bodyWidth.split('px')[0], 10) - 5;
  }

  drop(drop: CdkDragDrop<any[]>): void {
    const row = this.rows[drop.previousIndex];
    const id = row?.ID ?? row?.Hidden_ID;
    const orderChange = {
      previousIndex: drop.previousIndex,
      currentIndex: drop.currentIndex,
      id,
      rows: this.rows
    };

    this.orderChange.emit(orderChange);

    const data = {
      tableName: this.tableName,
      rowID: id,
      currentIndex: drop.currentIndex + 1,
      reorderType: this.reorderType
    };

    const reorderRequest = get(this.reorderConfig, 'request');

    if (reorderRequest) {
      reorderRequest(data).subscribe(res => {});
    }

  }

  isCheckbox(row, col): boolean {
    return typeof(row[col.prop]) === BOOLEAN;
  }



}
