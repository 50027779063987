<div class="flex flex-col w-full h-full">
  <app-topbar
    *ngIf="showTopbar$ | async"
    [isOffline]="isOffline$ | async"
    class="h-[45px]"
  ></app-topbar>

  <div
    *ngIf="(isOffline$ | async) && showOfflineBanner"
    id="offline-banner"
    class="flex flex-row flex-nowrap justify-between items-center w-full bg-warning"
  >
    <mat-icon class="mx-2">warning</mat-icon>

    <h5 class="flex flex-row justify-center items-center py-2 mb-0">You are currently working offline!</h5>

    <button
      mat-icon-button
      class="mx-2"
      tooltip="Hide Offline Banner"
      placement="left"
      (click)="closeOfflineBanner()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="flex flex-row h-full w-full">
    <app-sidebar
      [@slideInOut]="collapsedState$ | async"
      [visibility]="collapsedState$ | async"
      [isMobile]="isMobile"
    ></app-sidebar>

    <div class="stil-outlet flex flex-col grow">
      <router-outlet></router-outlet>
    </div>

    <app-right-nav
      *ngIf="!collapseRightNav"
      class="w-[40px]"
    ></app-right-nav>
  </div>
</div>
