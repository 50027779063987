import {
  ChangeDetectionStrategy, Component, Input, OnInit 
} from '@angular/core';
import { FLIP_AROUND_ANIMATION } from '~animations/flip-around.animation';
import { FacilityState } from '~core/states/facility/facility.state';
import { SignalrService } from '~signalr/services/signalr/signalr.service';

import { SidebarService } from '../../services/sidebar.service';
import { AppState } from '../../states/app/app.state';
import { SidebarState } from '../../states/sidebar/sidebar.state';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: [ './topbar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ FLIP_AROUND_ANIMATION ]
})
export class TopbarComponent implements OnInit {

  @Input() isOffline: boolean;

  childPageTitle$ = this.appState.childPageTitle$;
  collapsed$ = this.sidebarState.collapsed$;
  facilityName$ = this.appState.facilityName$;
  pageTitle$ = this.appState.pageTitle$;
  activePermitType$ = this.appState.activePermitType$;
  selectedId: any;
  showSettings = false;
  serverConnectionStatus$ = this.appState.serverConnectionStatus$;
  serverConnectionStatusIconEnabled = false;


  get isActiveDirectory() {
    return this.appState.get('user')?.IsActiveDirectoryUser;
  }

  get infoUrl() {
    return this.facilityState.get('generalSettings')?.infoUrl;
  }

  constructor(
    private appState: AppState,
    private sidebarSvc: SidebarService,
    private sidebarState: SidebarState,
    private facilityState: FacilityState,
    private signalR: SignalrService
  ) { }

  ngOnInit(): void {
    this.serverConnectionStatusIconEnabled = (localStorage.getItem('serverConnectionStatusIconEnabled')?.toLowerCase() === 'true');
  }



  toggleSidebar(): void {
    this.sidebarSvc.toggleSidebar();
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
  }

  broadcastButton(obj: any, event: MouseEvent) {
    if (event.shiftKey) {
      return this.signalR.reconnect();
    } else {
      return this.signalR.consoleMessageToAll({ message: 'Server Communication broadcast message test! (WebSockets)' });
    }
  }

}
