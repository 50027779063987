import { Injectable } from '@angular/core';
import { State, StateReplay } from '@devlime/ngx-state';
import { Observable } from 'rxjs';
import { Permission } from '~permissions/models/permission.model';

export interface PermitEditPermissions {
  footer: any;
  header: any;
  statusControl: any;
  tabs: any;
}

interface PermissionsStateProps {
  readonly allPermissions: Permission[];
  readonly activePermissions: any;
  readonly masterList: any;
  readonly permitEdit: any;
  readonly permitCreate: any;
  readonly permitIndex: any;
  readonly asset: any;
  readonly dashboard: any;
  readonly dashlet: any;
  readonly rightNav: any;
  readonly safetkonnect: any;
  readonly dataFilterGroup: any;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsState extends State<PermissionsStateProps> {

  @StateReplay<PermissionsState, Permission[]>()
  readonly allPermissions: Permission[];
  readonly allPermissions$: Observable<Permission[]>;

  @StateReplay<PermissionsState, any>()
  readonly activePermissions: any;
  readonly activePermissions$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly asset: any;
  readonly asset$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly dashboard: any;
  readonly dashboard$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly dashlet: any;
  readonly dashlet$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly rightNav: any;
  readonly rightNav$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly masterList: any;
  readonly masterList$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly permitCreate: any;
  readonly permitCreate$: Observable<any>;

  @StateReplay<PermissionsState, any>()
  readonly permitIndex: any;
  readonly permitIndex$: Observable<any>;

  @StateReplay<PermissionsState, PermitEditPermissions>()
  readonly permitEdit: PermitEditPermissions;
  readonly permitEdit$: Observable<PermitEditPermissions>;

  @StateReplay<PermissionsState, PermitEditPermissions>()
  readonly safetkonnect: PermitEditPermissions;
  readonly safetkonnect$: Observable<PermitEditPermissions>;

  @StateReplay<PermissionsState, any>()
  readonly dataFilterGroup: any;
  readonly dataFilterGroup$: Observable<any>;

  constructor() {
    super();
  }
}
