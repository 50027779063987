import {
  HttpHandler, HttpInterceptor, HttpParams, HttpRequest 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { each, isNull } from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class NullPatchInterceptor implements HttpInterceptor {

  constructor(
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const hasData = [ 'POST', 'PUT' ].includes(req.method);

    if (hasData && req.params) {
      let params = new HttpParams();

      each(req.params.keys(), key => {
        let value = req.params.get(key);

        if (isNull(value)) {
          value = '';
        }

        params = params.append(key, value);
      });

      req = req.clone({ params });
    }

    return next.handle(req);
  }


}
