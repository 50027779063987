import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ColumnMode, DatatableComponent, SelectionType, SortType
} from '@swimlane/ngx-datatable';
import * as _ from 'lodash';
import {
  cloneDeep, find, flatten, get, isEmpty, keys, map, reduce, sortBy, uniq, uniqBy
} from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppStateService } from 'src/app/app-state.service';
import { ComponentSettingsService } from 'src/app/core/services/component-settings.service';
import { ConfirmService } from '~confirmation/service/confirm.service';
import { AppState } from '~core/states/app/app.state';
import { DateService } from '~date/date-service/date.service';
import { ColumnSettingsModalComponent } from '../modals/column-settings-modal/column-settings-modal.component';
import { DataGridState } from './state/data-grid.state';

interface ICRUDConfig {
  entity?: any;
  referenceProp?: any;
  tab?: string;
  preCreate?: any;
  create?: any;
  postCreate?: any;
  preDelete?: any;
  delete?: any;
  postDelete?: any;
  preEdit?: any;
  edit?: any;
  postEdit?: any;
  import?: any;
  modal?: any;
}

@UntilDestroy()
@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: [ './data-grid.component.scss' ]
})
export class DataGridComponent implements OnInit, OnChanges, OnDestroy {
  private debouncedFilter: any;

  @Input() canAttach = null;
  @Input() canCreate = null;
  @Input() canDelete = null;
  @Input() canDetach = null;
  @Input() canEdit = null;
  @Input() canImport = null;
  @Input() canReorder = null;
  @Input() canRestoration = null;
  /* TODO: Remove columnsContainEditConfig once per column add/edit & controlType facility settings are added */
  @Input() columnsContainEditConfig = false;
  @Input() entityDisplayName = '';
  @Input() loading = false;
  @Input() rowReorder = false;
  @Input() rowHeight = 30;
  @Input() rowRestoration = false;
  @Input() showAllBtn = false;

  @Input() pristineColumns = []; /* FIXME: Temporary hack to work around columns being mutated :( */
  /* TODO: Unify template and regular columns */
  @Input() columns = [];
  @Input() templateColumns = [];

  /* FIXME: Eliminate CRUDConfig in favor of @Output()s to keep implementation specific logic out of the data-grid */
  @Input() useCRUDEvents = false;
  @Input() CRUDConfig: ICRUDConfig = {};
  @Input() useCreateEvent = false;
  @Input() useDeleteEvent = false;
  @Input() useEditEvent = false;
  /* TODO: Remove useCRUDEvents, CRUDConfig, useCreateEvent, useDeleteEvent, & useEditEvent once CRUDConfig is no longer used */

  @Input() enabled = true;
  @Input() columnMode = 'force';
  @Input() selectionType = 'single';
  @Input() pageSize = 25;
  @Input() title = '';
  @Input() rows = [];
  @Input() rowDetailTemplate;
  @Input() rowDetailHeight = 500;
  @Input() addConfig;
  @Input() autoColumn = false;
  @Input() filterModels = [];
  @Input() showDeselector = false;
  @Input() selectTypeColumns = [];
  @Input() multiSelectTypeColumns = [];
  @Input() selected = [];
  @Input() displayChecked;
  @Input() selectChecked;
  @Input() FAReorderConfig;
  @Input() headerCheckboxable = true;
  @Input() checkboxable = false;
  @Input() FSRequiredFields = [];
  @Input() showFilters = false;
  @Input() showAddForm = false;
  @Input() showEditForm = false;
  @Input() translatePath: string;

  @Output() createSaved = new EventEmitter<any[]>();
  @Output() deleteClicked = new EventEmitter<any[]>();
  @Output() editClick = new EventEmitter<any>();
  @Output() editSaved = new EventEmitter<any[]>();
  @Output() import = new EventEmitter<any[]>();
  @Output() add = new EventEmitter<any>();
  @Output() doubleClick = new EventEmitter<any>();
  @Output() propCheckboxToggled = new EventEmitter<any>();
  @Output() selectRow = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() rowCollapsed = new EventEmitter<any>();
  @Output() rowExpanded = new EventEmitter<any>();
  @Output() deselected = new EventEmitter<any>();
  @Output() selectedRowIndex = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>();
  @Output() showAll = new EventEmitter<any>();
  @Output() checkChange = new EventEmitter<any>();
  @Output() reorderRow = new EventEmitter<any>();

  @ViewChild('myTable') table: DatatableComponent;
  @ViewChild('hdrTpl', { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('selectHeader', { static: true }) selectHeader: TemplateRef<any>;
  @ViewChild('multiSelectHeader', { static: true }) multiSelectHeader: TemplateRef<any>;
  @ViewChild('propCheckboxTmpl', { static: true }) propCheckboxTmpl: TemplateRef<any>;

  messages: any = {
    emptyMessage: 'No Records',
    totalMessage: 'Results',
    selectedMessage: 'Selected'
  };

  eventSrcEle = null;
  reorderIndex;

  contextmenuX: any;
  contextmenuY: any;
  contextmenu = false;
  contextmenuData: any;

  scrollOffsetY = 0;
  SelectionType = SelectionType;
  SortType = SortType;
  ColumnMode = ColumnMode;
  bsModalRef: BsModalRef;
  storedRows = [];
  selectedRow;
  showAllToggled = false;
  editing: any = {};
  newItem: any = {};
  cache;
  temp;
  displayedRows = [];
  allRows;
  reordering = false;
  currentFilters: any = {}
  filters: any = {};
  selectFilterOptions = {};
  filtersSet = false;
  activeSelectFilters = {};
  allColumns = [];
  offset = 0;
  filterSubs = [];
  autoColumnAdded = false;
  subscriptions: Subscription[] = [];
  validationErrors = [];
  selectedReorderType;
  page = {
    size: this.pageSize,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    offset: 0
  };
  sortFn: () => any;

  @HostListener('document:click', [ '$event' ])
  clickedOutside($event) {
    if ($event.target.className !== 'dropdown-item context-menu') {
      this.contextmenu = false;
    }
  }

  get addOrEditFormActive(): boolean {
    return this.showAddForm || this.showEditForm;
  }

  get showCreate(): boolean {
    if (this.canCreate === null) {
      return (
        _.get(this.CRUDConfig, 'create.enabled') &&
        !(this.showAddForm || this.showEditForm || this.showFilters)
      );
    }

    return this.canCreate;
  }

  get showDelete(): boolean {
    if (this.canDelete === null) {
      return (
        _.get(this.CRUDConfig, 'delete.enabled') &&
        this.selected.length &&
        !(this.showAddForm || this.showEditForm)
      );
    }

    return this.canDelete;
  }

  get showEdit(): boolean {
    if (this.canEdit === null) {
      return (
        _.get(this.CRUDConfig, 'edit.enabled') &&
        this.selected.length &&
        !(this.showAddForm || this.showEditForm)
      );
    }

    return this.canEdit;
  }

  get showImport(): boolean {
    if (this.canImport === null) {
      return _.get(this.CRUDConfig, 'import.enabled');
    }

    return this.canImport;
  }

  get showReorder(): boolean {
    if (this.canReorder === null ) {
      return this.rowReorder;
    }

    return this.canReorder;
  }

  get showRestoration(): boolean {
    if (this.canRestoration === null ) {
      return this.rowRestoration;
    }
    return this.canRestoration;
  }

  constructor(
    private modalService: BsModalService,
    private toastrSvc: ToastrService,
    private appState: AppState,
    private appStateSvc: AppStateService,
    private cdr: ChangeDetectorRef,
    public compSettingsSvc: ComponentSettingsService,
    private dataGridState: DataGridState,
    private dateSvc: DateService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit() {
    if (
      this.CRUDConfig &&
      this.CRUDConfig.modal &&
      this.CRUDConfig.entity === 'Tags Item'
    ) {
      // this.state.$deSelectAll.pipe(delay(150)).subscribe((res) => {
      this.appStateSvc.$deSelectAll
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (this.table.selected.length === this.table.rows.length) {
            this.table.onHeaderSelect(res);
          }
          this.table.selected = [];
        });
    }
  }

  sortComparator(propA, propB) {
    const dateA = new Date(propA)?.getTime();
    const dateB = new Date(propB)?.getTime();

    if (dateA < dateB) {
      return -1;
    }

    if (dateA > dateB) {
      return 1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['columns']) {
      this.columns = map(this.columns, column => {
        column.comparator = null;

        if (column.prop.toLowerCase().includes('date')) {
          column.comparator = this.sortComparator.bind(this);
        }

        return column;
      });
    }

    if (
      this.CRUDConfig &&
      this.CRUDConfig.modal &&
      changes['selected'] &&
      !changes['selected'].firstChange
    ) {
      return;
    }

    if (this.autoColumn && this.rows) {
      this.autoColumnAdded = true;
      const newColumns = [];

      for (const key of keys(this.rows[0])) {
        if (!key.startsWith('Hidden_') && key !== 'ID') {
          newColumns.push({ prop: key });
        }
      }

      if (this.rows.length === 0) {
        for (const key in this.columns) {
          if (!this.columns[key]['prop'].startsWith('Hidden_')) {
            newColumns.push({ prop: this.columns[key]['prop'] });
          }
        }
      }

      this.columns = newColumns;
      this.allColumns = this.columns;
    }

    if (changes['rows']) {
      this.allRows = this.rows;
      this.updateFilter();
      this.dataGridState.set('permits', this.rows);
    }

    for (const column of this.columns) {
      if (!this.isSelectColumn(column.prop)) {
        if (!this.multiSelectTypeColumns.includes(column.prop)) {
          column.headerTemplate = this.hdrTpl;
        } else {
          column.headerTemplate = this.multiSelectHeader;
          this.selectFilterOptions[column.prop] = _.compact(uniq(sortBy(
            flatten(map(uniqBy(this.rows, column.prop), (row) => {
              if (typeof row[column.prop] === 'string') {
                return row[column.prop].split(',');
              }
            }))
          )));
        }
      } else {
        column.headerTemplate = this.selectHeader;
        this.selectFilterOptions[column.prop] = _.sortBy(
          _.map(_.uniqBy(this.allRows, column.prop), (row) => row[column.prop])
        );
        if (column.prop === 'IsMasterList') {
          this.selectFilterOptions[column.prop] = [ true, false ];  // force values, if all rows true then you cant select false & viceversa
        }
      }
    }

    for (const column of this.templateColumns) {
      if (column.filter) {
        if (this.multiSelectTypeColumns.includes(column.prop)) {
          column.headerTemplate = this.multiSelectHeader;
          this.selectFilterOptions[column.prop] = _.compact(uniq(sortBy(
            flatten(map(uniqBy(this.rows, column.prop), (row) => {
              if (typeof row[column.prop] === 'string') {
                return row[column.prop].split(',');
              }
            }))
          )));
        }
      }
    }

    this.generateRowClasses(
      map(
        uniqBy(this.allRows, 'Hidden_IndexColor'),
        (item: any) => item.Hidden_IndexColor
      )
    );

    if (this.filterModels.length) {
      for (const model of this.filterModels) {
        const isControl = !!(model?.control?.valueChanges);
        if (isControl) {
          model.control.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe((val) => this.updateFilter(val, model.prop));
        } else {
          this.updateFilter(model.control, model.prop);
        }
      }
    }
  }

  getColumnTemplate(template: string | TemplateRef<any> = null): TemplateRef<any> | null {
    const columnTemplates = {
      propCheckbox: this.propCheckboxTmpl
    };

    if (typeof template === 'string') {
      return columnTemplates[template];
    }

    return template;
  }

  getTooltip(action: string): string {
    const entity = _.get(this.CRUDConfig, 'entity', this.title);

    return `${action} ${entity}`;
  }

  updateFilter(value?: any, prop?: string) {
    this.currentFilters = { ...this.filters };
    this.loading = true;

    if (prop) {
      if (Array.isArray(value)) {
        value = value.join('.');
      }

      value = value.toLowerCase();

      if (value.length) {
        this.filters[prop] = value;
      } else {
        delete this.filters[prop];
      }
    }

    this.rows = reduce(this.filters, (filteredRows, filterValue, filterKey) => {
      if (filterValue && filterKey) {
        if ([
          'Status Date/Time',
          'CreatedDate',
          'Requested By Date/Time' 
        ].includes(filterKey)) {
          filteredRows = filteredRows.filter(row => {
            const includesDate = row['CreatedDate'].startsWith(filterValue);
            const includesDisplayedDate = this.dateSvc.convertDateForDisplay(row['CreatedDate']).startsWith(filterValue);

            return includesDate || includesDisplayedDate;
          });
        } else if (this.selectTypeColumns.includes(filterKey)) {
          filteredRows = filteredRows.filter(row => {
            const rowValue = `${row[filterKey]}`.toLowerCase();
            return (row[filterKey] != null && (filterValue.includes(rowValue)));
          });
        } else {
          filteredRows = filteredRows.filter(row => {
            const rowValue = `${row[filterKey]}`.toLowerCase();
            return (row[filterKey] != null && (rowValue.includes(filterValue)));
          });
        }
      }

      return filteredRows;
    }, [ ...this.allRows ]);

    this.loading = false;

    this.dataGridState.set('permits', this.rows);
  }

  reorderDrop({
    currentIndex, id, previousIndex
  }) {
    this.rows = _.cloneDeep(this.rows);
    moveItemInArray(
      this.rows,
      previousIndex,
      currentIndex
    );
    this.reorderRow.emit({
      'id':id,
      'prev':previousIndex,
      'current':currentIndex
    })
  }

  expandRow(row) {
    this.table.rowDetail.collapseAllRows();
    this.rowExpanded.emit(row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  collapseRow(row) {
    this.rowCollapsed.emit(row);
    this.table.rowDetail.collapseAllRows();
  }

  onDetailToggle(event) {
  }

  isSelectColumn(columnProp) {
    return this.selectTypeColumns.includes(columnProp);
  }

  getFilterCriteria(event, columnProp) {
    const filterCriteria = event.value;
    this.updateFilter(filterCriteria.join(''), columnProp);
  }

  getMultiSelectFilterCriteria(event, columnProp) {
    const filterCriteria = event.value;
    this.updateFilter(filterCriteria.join(','), columnProp);
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;
    this.clearFilters();
  }

  clearFilters() {
    for (const filter of keys(this.filters)) {
      this.updateFilter('', filter);
    }
  }

  toggleReorder(selectedReorderType) {
    this.reordering = !this.reordering;
    if (!this.reordering) {
      this.appStateSvc.refreshAfterReorder.next(true);
      return;
    }
    this.selectedReorderType = selectedReorderType;
  }

  cancelAction() {
    this.showAddForm = false;
    this.showEditForm = false;
    this.editing = {};
    this.newItem = {};
  }

  showAllClicked() {
    this.showAllToggled = !this.showAllToggled;
    this.showAll.emit();
  }

  addItem() {
    if (this.CRUDConfig.create.reroute !== undefined) {
      this.CRUDConfig.create.request();
    } else {
      this.showAddForm = true;
    }
  }

  async saveItem() {

    if (this.showAddForm) {
      if (this.areRequiredFieldsValid()) {
        if (!this.useCRUDEvents && !this.useCreateEvent) {
          this.createItem();
        } else {
          this.newItem.CreatedByUserID = this.appState.get('user').ID;
          this.newItem.ParentId = this.appState.get('facilityId');
          this.createSaved.emit(this.newItem);
          this.showAddForm = false;
        }
      } else {
        this.showValidationMessages(this.validationErrors);
      }
    }

    if (this.showEditForm) {
      let editConfirmed = true;
      if (this.CRUDConfig.edit.editConfirmationNeeded) {
        editConfirmed = await this.confirmService.confirm(`EDIT setting ${this.selected[0].UniqueName}`, 'Edit').toPromise();
      }

      if (editConfirmed) {
        if (this.areRequiredFieldsValid()) {
          if (!this.useCRUDEvents && !this.useEditEvent) {
            this.updateItem();
          } else {
            this.findRowToEdit(this.rows);
            this.findRowToEdit(this.allRows);
            this.rows = cloneDeep(this.rows);
            this.allRows = cloneDeep(this.allRows);
            this.editSaved.emit([ this.editing ]);
            this.showEditForm = false;
          }
        } else {
          this.showValidationMessages(this.validationErrors);
        }
      }
    }
  }

  isFieldBlank(content): boolean {
    return content === undefined || content.trim() === '' || content === null;
  }

  areRequiredFieldsValid(): boolean {
    this.validationErrors = [];

    if (this.showAddForm) {
      this.FSRequiredFields.forEach((field) => {
        if (this.isFieldBlank(this.newItem[field])) {
          this.validationErrors.push(field);
        }
      });
      if (this.CRUDConfig.create.DBRequiredFields) {
        this.CRUDConfig.create.DBRequiredFields.forEach((field) => {
          if (this.isFieldBlank(this.newItem[field])) {
            this.validationErrors.push(field);
          }
        });
      }
    }
    if (this.showEditForm) {
      this.FSRequiredFields.forEach((field) => {
        if (this.isFieldBlank(this.editing[field])) {
          this.validationErrors.push(field);
        }
      });
      if (this.CRUDConfig.edit.DBRequiredFields) {
        this.CRUDConfig.edit.DBRequiredFields.forEach((field) => {
          if (this.isFieldBlank(this.editing[field])) {
            this.validationErrors.push(field);
          }
        });
      }
    }

    return this.validationErrors.length === 0 ? true : false;
  }

  showValidationMessages(validationErrors) {
    const uniqueValidationErrors = [ ...new Set(validationErrors) ];
    uniqueValidationErrors.forEach((field) => {
      this.toastrSvc.error(`${field} is required.`, ``, {
        extendedTimeOut: 10000
      });
    });
  }

  removeNullandUndefined() {
    const editingCopy = _.cloneDeep(this.editing);
    for (const property in editingCopy) {
      if (editingCopy[property] == null) {
        this.editing[property] = '';
      }
    }
  }

  findRowToEdit(arr) {
    arr[
      arr.findIndex((p, index) => p === this.selectedRow)
    ] = this.editing;
  }

  updateItem() {
    this.loading = true;
    this.removeNullandUndefined();

    this.subscriptions.push(
      this.CRUDConfig.edit.request(this.editing).subscribe((res) => {
        if (!isEmpty(res)) {
          this.findRowToEdit(this.rows);
          this.findRowToEdit(this.allRows);
          this.rows = cloneDeep(this.rows);
          this.allRows = cloneDeep(this.allRows);
          this.toastrSvc.success(
            `${
              this.editing[this.CRUDConfig.referenceProp]
            } has been successfully edited.`,
            `${this.CRUDConfig.entity} Saved`
          );
          this.showAddForm = false;
          this.editing = {};
          this.showEditForm = false;
          this.loading = false;
        }
        this.loading = false;
      })
    );
  }

  createItem() {
    if (!this.useCRUDEvents && !this.useCreateEvent) {
      // check for preCreate
      if (!this.CRUDConfig.preCreate) {
        this.CRUDConfig.preCreate = { request: () => of(null) };
      }

      this.subscriptions.push(
        this.CRUDConfig.preCreate
          .request()
          .pipe(
            switchMap((res: any) => {
              return of(res);
            }),
            switchMap((res: any) => {
              this.loading = true;
              this.newItem.CreatedByUserID = this.appState.get('user').ID;
              this.newItem.ParentId = this.appState.get('facilityId');
              return this.CRUDConfig.create.request(this.newItem);
            }),
            switchMap((res: any) => {
              if (!isEmpty(res)) {
                this.rows.unshift(res);
                this.rows = JSON.parse(JSON.stringify(this.rows));
                this.toastrSvc.success(
                  `${
                    this.newItem[this.CRUDConfig.referenceProp]
                  } has been successfully created.`,
                  `${this.CRUDConfig.entity} Saved`
                );
                this.showAddForm = false;
                this.newItem = {};
                this.loading = false;
                if (this.CRUDConfig.postCreate) {
                  return this.CRUDConfig.postCreate.request(
                    res,
                    _.cloneDeep(this.rows)
                  );
                } else {
                  return of(this.rows);
                }
              }
              this.loading = false;
              return of(this.rows);
            })
          )
          .subscribe((rows: any) => {
            this.rows = _.cloneDeep(rows);
          })
      );
    }
  }

  updateAddItem({ val, col }): void {
    this.newItem[col] = val;
  }

  updateNewItem(value, prop) {
    this.newItem[prop] = value;
  }

  updateEditProp(update) {
    this.editing[update.col] = update.val;
  }

  findRowToRemove(arr) {
    arr.splice(
      arr.findIndex((p, index) => p === this.selectedRow),
      1
    );
  }

  async deleteItem() {
    let deleteConfirmed = true;

    if (this.CRUDConfig.delete?.deleteConfirmationNeeded) {
      deleteConfirmed = await this.confirmService.confirm(`DELETE this item`).toPromise();
    }

    if (deleteConfirmed) {
      this.deleteClicked.emit(this.selected);

      if (!this.useCRUDEvents && !this.useDeleteEvent) {
        // check for preDelete
        if (!this.CRUDConfig.preDelete) {
          this.CRUDConfig.preDelete = { request: () => of(null) };
        }

        this.subscriptions.push(
          this.CRUDConfig.preDelete
            .request()
            .pipe(
              switchMap((res: any) => {
                return of(res);
              }),
              switchMap((res: any) => {
                this.loading = true;
                const id = this.selectedRow.ID
                  ? this.selectedRow.ID
                  : this.selectedRow.Hidden_ID;
                return this.CRUDConfig.delete.request(id);
              }),
              switchMap((res: any) => {
                this.findRowToRemove(this.rows);
                this.rows = cloneDeep(this.rows);
                this.toastrSvc.info(
                  `${this.selectedRow[this.CRUDConfig.referenceProp]
                  } has been successfully removed.`,
                  `${this.CRUDConfig.entity} Deleted`
                );
                this.loading = false;
                if (this.CRUDConfig.postDelete) {
                  return this.CRUDConfig.postDelete.request(
                    { ...this.selectedRow },
                    _.cloneDeep(this.rows)
                  );
                } else {
                  return of(this.rows);
                }
              })
            )
            .subscribe((rows: any) => {
              this.selectedRow = null;
              this.rows = _.cloneDeep(rows);
            })
        );
      }
    }
  }

  editItem() {
    this.editClick.emit();

    if (this.CRUDConfig.edit.reroute !== undefined) {
      this.CRUDConfig.edit.request(this.selectedRow);
    } else {
      this.showEditForm = true;
      this.editing = cloneDeep(this.selectedRow);
    }
  }

  openGridSettings() {
    this.allColumns = this.columns;
    const state = {
      columns: this.columns,
      allColumns: this.allColumns,
      class: 'modal-lg dark-modal'
    };
    this.bsModalRef = this.modalService.show(
      ColumnSettingsModalComponent,
      state
    );
    this.bsModalRef.content.columns = this.columns;
    this.bsModalRef.content.allColumns = this.allColumns;
    this.modalService.onHide.subscribe(
      () => (this.columns = this.bsModalRef.content.columns)
    );
  }

  generateRowClasses(uniqueColors: string[]) {
    for (const color of uniqueColors) {
      if (color != null) {
        this.createClass(
          `color-${color.split('#')[1]}`,
          `background-color: ${color}`
        );
      }
    }
  }

  getRowClass(row) {
    const cssClass = {};

    if (row.Hidden_IndexColor) {
      cssClass[`color-${row.Hidden_IndexColor.split('#')[1]}`] = true;
    }

    if (this.editing !== undefined && row.ID === this.editing.ID) {
      cssClass['editing-row'] = true;
    }

    return cssClass;
  }

  createClass(name, rules) {
    const style: HTMLStyleElement = document.createElement('style');
    style.innerHTML = `.${name} { ${rules} }`;
    document.getElementsByTagName('head')[0].appendChild(style);
  }

  onSelect(row) {
    if (row.selected) {
      if (!this.showEditForm) {
        this.selectRow.emit(row.selected);
        this.selected = row.selected;
        this.selectedRow = row.selected[0];
        this.getRowIndex(row.selected);
      } else {
        this.selected = [ this.selectedRow ];
      }
    }
  }

  selectStopPropagation(event: Event) {
    event.stopPropagation();
  }

  getRowIndex(row) {
    if (!row.length) { return; }
    return this.rows.findIndex((p, idx) => p.ID === row[0].ID);
  }

  deselect() {
    this.selected = [];
    this.deselected.emit();
  }

  onActivate(event) {
    if (event.type === 'dblclick') {
      this.doubleClick.emit(event);
    }

    if (event.type === 'click') {
      this.rowClick.emit(event?.row);
    }
  }

  onPage(event) {
    this.pageChange.emit(this.page);
  }

  importItems(): void {
    this.import.emit();

    if (!this.useCRUDEvents && get(this.CRUDConfig, 'import.showModal')) {
      this.CRUDConfig.import.showModal();
    }
  }

  getColumnConfig(column): any {
    /* TODO: remove this function by generating the type and editable info from permit tabsettings */
    let columnEditConfig = get(this.CRUDConfig, `edit.columnConfig.${column.prop}`, {});

    if (this.columnsContainEditConfig) {
      columnEditConfig = get(find(this.pristineColumns, { prop: column.prop }), 'config', {});
    }

    return columnEditConfig;
  }

  public onTableContextMenu(event) {
    if (!(this.showAddForm || this.showEditForm || this.showFilters)) {
      this.contextmenuX = event.event.pageX;
      this.contextmenuY = event.event.pageY;
      this.contextmenu = true;
      this.contextmenuData = event.content;
      this.selected = [ event.content ];
      this.selectedRow = event.content;
      event.event.preventDefault();
      event.event.stopPropagation();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  isColumnConfigCheckbox(column): boolean {
    const isEnabledInColumnConfig = get(find(this.pristineColumns, { prop: column.prop }), 'config.controlType', '') === 'checkbox';
    const isEnabledForAdd = get(this.CRUDConfig, `edit.columnConfig.${column.prop}.controlType`, '') === 'checkbox';
    const isEnabledForEdit = get(this.CRUDConfig, `create.columnConfig.${column.prop}.controlType`, '') === 'checkbox';

    return isEnabledInColumnConfig || isEnabledForAdd || isEnabledForEdit;
  }

  isDisabled(isChecked: boolean): boolean {
    let isDisabled = true;

    if ((isChecked && this.canDetach) || (!isChecked && this.canAttach)) {
      isDisabled = false;
    }

    return isDisabled;
  }

  togglePropCheckbox(row: any, prop: string, index: number): void {
    row[prop] = !row[prop];
    this.propCheckboxToggled.emit({
      row,
      index
    });
  }
}
