import {
  Component, EventEmitter, Input, Output 
} from '@angular/core';
import { get } from 'lodash';
import { mergeWith } from '~shared/decorators/merge-with.decorator';

export const DEFAULT_COLUMN_EDIT_CONFIG = {
  controlType: 'text'
};

@Component({
  selector: 'app-data-grid-editor',
  templateUrl: './data-grid-editor.component.html',
  styleUrls: [ './data-grid-editor.component.scss' ]
})
/* TODO: rename this component to indicate it only controls a single column */
export class DataGridEditorComponent {

  @Input() action = 'edit';
  @Input() column;
  @Input() row?: any;
  @Input() value?: any;

  @mergeWith(DEFAULT_COLUMN_EDIT_CONFIG)
  @Input() config;

  @Output() valueUpdated = new EventEmitter();

  getFieldValue(): any {
    return get(this.row, this.column.prop, '');
  }

  getStyle(): any {
    let style = {};

    if (this.action === 'edit') {
      style = {
        margin: '0px auto',
        width: '100%',
        height: '20px'
      };
    }

    return style;
  }

  updateNewItem(val, col) {
    this.valueUpdated.emit({
      val,
      col 
    });
  }

}
