import { Injectable } from '@angular/core';
import {
  State, StateReplay, StateSubject 
} from '@devlime/ngx-state';
import { Observable } from 'rxjs';

export interface SidebarData {
  readonly collapsed: boolean;
  readonly dashboardRouted: boolean;
  readonly rightNavCollapsed: boolean;
  readonly activePermitGroup: number;
  readonly navLinks: any;
  readonly rightNavEnabled: any;
  readonly showReorder: boolean;
  readonly updateNavLinks: boolean;
}

type SidebarDataKey = keyof SidebarData;

@Injectable({
  providedIn: 'root'
})
export class SidebarState extends State<SidebarData> {

  private persistedProps = [ 'activePermitGroup', 'collapsed' ];

  @StateReplay<SidebarState, boolean>()
  private collapsed: boolean;
  readonly collapsed$: Observable<boolean>;

  @StateSubject<SidebarState, boolean>()
  private dashboardRouted: boolean;
  readonly dashboardRouted$: Observable<void>;

  @StateReplay<SidebarState, boolean>()
  private rightNavCollapsed: boolean;
  readonly rightNavCollapsed$: Observable<boolean>;

  @StateReplay<SidebarState, number>()
  private activePermitGroup: number;
  readonly activePermitGroup$: Observable<number>;

  @StateReplay<SidebarState, any>()
  private navLinks: any;
  readonly navLinks$: Observable<any>;

  @StateReplay<SidebarState, any>()
  private rightNavEnabled: any;
  readonly rightNavEnabled$: Observable<any>;

  @StateReplay<SidebarState, boolean>()
  private showReorder: boolean;
  readonly showReorder$: Observable<boolean>;

  @StateSubject<SidebarState, boolean>()
  private updateNavLinks: boolean;
  readonly updateNavLinks$: Observable<boolean>;

  constructor() {
    super();
  }

  set(prop: SidebarDataKey, value?: SidebarState[SidebarDataKey]): void {
    super.set(prop, value);

    if (this.persistedProps.includes(prop)) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      sessionStorage.setItem(prop, value);
    }
  }

  init() {
    this.loadsessionStorage();
  }

  private loadsessionStorage(): void {
    this.persistedProps.forEach((key: SidebarDataKey) => {
      const value = sessionStorage.getItem(key);

      if (value) {
        super.set(key, JSON.parse(value));
      }
    });
  }

}
