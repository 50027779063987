
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { ContextOptions } from '@ngneat/cashew/lib/cache-context';
import { Observable } from 'rxjs';
import { FacilitySetting } from '~shared/models/facility-setting.model';

@Injectable({
  providedIn: 'root'
})
export class FacilitySettingSavedPermitDataService {
  constructor(private http: HttpClient) {}

  create(
    TableName,
    FacilityID,
    ItemType,
    PermitGroupID,
    VarietyTypeID,
    StatusID,
    StatusActionID,
    StatusActionStatusID,
    SettingID,
    SettingValue,
    Attribute,
    IsStandard,
    Config?) {

    const body = {
      TableName,
      FacilityID,
      ItemType,
      PermitGroupID,
      VarietyTypeID,
      StatusID,
      StatusActionID,
      StatusActionStatusID,
      SettingID,
      SettingValue,
      Attribute,
      IsStandard,
      Config
    };
    return this.http.post(`facilitySettingSavedPermit/create`, body, { params: body as any });
  }

  edit(facilitySettingSavedID: number, tableName, FacilityID, settingObject) {
    const {
      ItemType,
      PermitGroupID,
      VarietyTypeID,
      StatusID,
      StatusActionID,
      StatusActionStatusID,
      ID,
      SettingID,
      SettingValue,
      Attribute,
      IsStandard,
      Config
    } = settingObject;

    const body = {
      facilitySettingSavedID,
      tableName,
      FacilityID,
      ItemType,
      PermitGroupID,
      VarietyTypeID,
      StatusID,
      StatusActionID,
      StatusActionStatusID,
      ID,
      SettingValue,
      Attribute,
      IsStandard,
      SettingID,
      Config
    };
    return this.  http.put(`facilitySettingSavedPermit/edit`, body,  { params: body as any });
  }

  delete(facilitySettingSavedID: number) {
    const params = {
      TableName: 'FacilitySettingSavedPermit',
      facilitySettingSavedID
    };
    return this.http.delete(`facilitySettingSavedPermit/delete`, { params: params as any });
  }

  view(facilitySettingSavedID: number) {
    const params = { facilitySettingSavedID };
    return this.http.get(`facilitySettingSavedPermit/view`);
  }

  getID(
    tableName: string,
    facilityID: number,
    permitType: string,
    varietyTypeID: number,
    statusID: number,
    statusActionID: number,
    statusActionStatusID: number,
    settingID: number
  ) {
    const params = {
      tableName,
      facilityID,
      permitType,
      varietyTypeID,
      statusID,
      statusActionID,
      statusActionStatusID,
      settingID
    };
    return this.http.get(`facilitySettingSavedPermit/getID`);
  }

  getRecordsForFacilityID(TableName: string, facilityID: number, key = ''): Observable<FacilitySetting[]> {
    const params = {
      TableName,
      FacilityID: `${facilityID}`
    };

    return this.http.get<FacilitySetting[]>(`facilitySettingSavedPermit/getRecordsForFacilityID`, {
      params,
      context: withCache({ key })
    });
  }

  getRecordsForSettingID(tableName: string, facilityID: number, settingID: number) {
    const params = {
      tableName,
      facilityID,
      settingID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForSettingID`, { params: params as any });
  }

  getRecordsForSettingIDAndItemType(tableName: string, facilityID: number, settingID: number, itemType: string, isStandard: boolean) {
    const params = {
      tableName,
      facilityID,
      settingID,
      itemType,
      isStandard
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForSettingIDAndItemType`, { params: params as any });
  }

  getRecordsForStatusActionStatusID(tableName: string, facilityID: number, statusActionStatusID: number) {
    const params = {
      tableName,
      facilityID,
      statusActionStatusID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForStatusActionStatusID`);
  }

  getRecordsForStatusID(tableName: string, facilityID: number, statusID: number) {
    const params = {
      tableName,
      facilityID,
      statusID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForStatusID`);
  }

  getRecordsForVarietyTypeID(tableName: string, facilityID: number, varietyTypeID: number) {
    const params = {
      tableName,
      facilityID,
      varietyTypeID
    };
    return this.http.get(`facilitySettingSavedPermit/getRecordsForVarietyTypeID`);
  }

  cloneVarietyTypeSettings(tableName: string, sourceVarietyTypeID: number, destinationVarietyTypeID: number) {
    const params = {
      tableName,
      sourceVarietyTypeID,
      destinationVarietyTypeID
    };
    return this.http.get(`facilitySettingSavedPermit/cloneVarietyTypeSettings`, { params: params as any });
  }

  cloneStatusSettings(tableName: string, sourceVarietyTypeID: number, destinationVarietyTypeID: number, sourceStatusID: number, destinationStatusID: number) {
    const params = {
      tableName,
      sourceVarietyTypeID,
      destinationVarietyTypeID,
      sourceStatusID,
      destinationStatusID
    };
    return this.http.get(`facilitySettingSavedPermit/cloneStatusSettings`, { params: params as any });
  }

  cloneStatusActionSettings(tableName: string, sourceVarietyTypeID: number, destinationVarietyTypeID: number, sourceStatusID: number, destinationStatusID: number, sourceStatusActionID, destinationStatusActionID) {
    const params = {
      tableName,
      sourceVarietyTypeID,
      destinationVarietyTypeID,
      sourceStatusID,
      destinationStatusID,
      sourceStatusActionID,
      destinationStatusActionID
    };

    return this.http.get(`facilitySettingSavedPermit/cloneStatusActionSettings`, { params: params as any });
  }

  cloneStatusActionStatusSettings(tableName: string,
    sourceVarietyTypeID: number, destinationVarietyTypeID: number,
    sourceStatusID: number, destinationStatusID: number,
    sourceStatusActionID: number, destinationStatusActionID: number,
    sourceStatusActionStatusID: number, destinationStatusActionStatusID: number) {
    const params = {
      tableName,
      sourceVarietyTypeID,
      destinationVarietyTypeID,
      sourceStatusID,
      destinationStatusID,
      sourceStatusActionID,
      destinationStatusActionID,
      sourceStatusActionStatusID,
      destinationStatusActionStatusID
    };
    return this.http.get(`facilitySettingSavedPermit/cloneStatusActionStatusSettings`, { params: params as any });
  }

  reorderSettingFromList(
    itemList: string[],
    settingID: number,
    isStandard: boolean,
    tableName: string,
    facilityId: number,
    itemType: string,
    permitGroupId: number,
    statusId: number,
    statusActionId: number,
    statusActionStatusId: number,
    varietyTypeId: number
  ): Observable<any> {


    const body = {
      settingID,
      isStandard,
      tableName: tableName,
      facilityId: facilityId,
      itemType: itemType,
      permitGroupId: permitGroupId,
      statusId: statusId,
      statusActionId: statusActionId,
      statusActionStatusId: statusActionStatusId,
      varietyTypeId: varietyTypeId,
      itemList: itemList
    };

    return this.http.put(`facilitySettingSavedPermit/reorderSettingFromList`, body);
  }

}
