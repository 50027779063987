import { merge } from 'lodash';

export function mergeWith(defaultValue: any): (target: any, key: string) => void {
  return function(target: any, key: string) {
    const setKey = `_${key}`;
    Object.defineProperty(target, setKey, { writable: true });
    Object.defineProperty(target, key, {
      configurable: false,
      get() { return this[setKey] || defaultValue; },
      set(newValue) { this[setKey] = merge({}, defaultValue, newValue); }
    });
  };
}
