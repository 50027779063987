import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
  name: 'safetk-db',
  version: 10,
  objectStoresMeta: [
    {
      store: 'auth',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false
      },
      storeSchema: [
        {
          name: 'exp',
          keypath: 'exp',
          options: { unique: true }
        },
        {
          name: 'expRefresh',
          keypath: 'expRefresh',
          options: { unique: true }
        },
        {
          name: 'refreshToken',
          keypath: 'refreshToken',
          options: { unique: true }
        },
        {
          name: 'token',
          keypath: 'token',
          options: { unique: true }
        },
        {
          name: 'user',
          keypath: 'user',
          options: { unique: true }
        }
      ]
    },
    {
      store: 'appState',
      storeConfig: {
        keyPath: 'key',
        autoIncrement: false
      },
      storeSchema: [
        {
          name: 'key',
          keypath: 'key',
          options: { unique: true }
        },
        {
          name: 'value',
          keypath: 'value',
          options: { unique: false }
        }
      ]
    },
    {
      store: 'facilityState',
      storeConfig: {
        keyPath: 'key',
        autoIncrement: false
      },
      storeSchema: [
        {
          name: 'key',
          keypath: 'key',
          options: { unique: true }
        },
        {
          name: 'value',
          keypath: 'value',
          options: { unique: false }
        }
      ]
    },
    {
      store: 'permits',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: false
      },
      storeSchema: [
        {
          keypath: 'tableName',
          name: 'tableName',
          options: { unique: false }
        },
        {
          keypath: 'id',
          name: 'id',
          options: { unique: false }
        },
        {
          keypath: 'parentID',
          name: 'parentID',
          options: { unique: false }
        },
        {
          keypath: 'varietyTypeID',
          name: 'varietyTypeID',
          options: { unique: false }
        },
        {
          keypath: 'varietyType',
          name: 'varietyType',
          options: { unique: false }
        },
        {
          keypath: 'prefixID',
          name: 'prefixID',
          options: { unique: false }
        },
        {
          keypath: 'prefix',
          name: 'prefix',
          options: { unique: false }
        },
        {
          keypath: 'logSerial',
          name: 'logSerial',
          options: { unique: false }
        },
        {
          keypath: 'assetId',
          name: 'assetId',
          options: { unique: false }
        },
        {
          keypath: 'task',
          name: 'task',
          options: { unique: false }
        },
        {
          keypath: 'recordOwnedByUserId',
          name: 'recordOwnedByUserId',
          options: { unique: false }
        },
        {
          keypath: 'createdFromPermitId',
          name: 'createdFromPermitId',
          options: { unique: false }
        },
        {
          keypath: 'isStandard',
          name: 'isStandard',
          options: { unique: false }
        },
        {
          keypath: 'isOffline',
          name: 'isOffline',
          options: { unique: false }
        },
        {
          keypath: 'lastViewDate',
          name: 'lastViewDate',
          options: { unique: false }
        },
        {
          keypath: 'companyDoingWork',
          name: 'companyDoingWork',
          options: { unique: false }
        },
        {
          keypath: 'personDoingWork',
          name: 'personDoingWork',
          options: { unique: false }
        },
        {
          keypath: 'numberOfPeopleWorking',
          name: 'numberOfPeopleWorking',
          options: { unique: false }
        },
        {
          keypath: 'durationYears',
          name: 'durationYears',
          options: { unique: false }
        },
        {
          keypath: 'durationMonths',
          name: 'durationMonths',
          options: { unique: false }
        },
        {
          keypath: 'durationDays',
          name: 'durationDays',
          options: { unique: false }
        },
        {
          keypath: 'durationHours',
          name: 'durationHours',
          options: { unique: false }
        },
        {
          keypath: 'durationMinutes',
          name: 'durationMinutes',
          options: { unique: false }
        },
        {
          keypath: 'startDate',
          name: 'startDate',
          options: { unique: false }
        },
        {
          keypath: 'expireDate',
          name: 'expireDate',
          options: { unique: false }
        },
        {
          keypath: 'estimatedRestoreDate',
          name: 'estimatedRestoreDate',
          options: { unique: false }
        },
        {
          keypath: 'comments',
          name: 'comments',
          options: { unique: false }
        },
        {
          keypath: 'purposeOfEntry',
          name: 'purposeOfEntry',
          options: { unique: false }
        },
        {
          keypath: 'reasonOfImpairment',
          name: 'reasonOfImpairment',
          options: { unique: false }
        },
        {
          keypath: 'referenceDrawings',
          name: 'referenceDrawings',
          options: { unique: false }
        },
        {
          keypath: 'remarks',
          name: 'remarks',
          options: { unique: false }
        },
        {
          keypath: 'specialInstructions',
          name: 'specialInstructions',
          options: { unique: false }
        },
        {
          keypath: 'typeOfCommunication',
          name: 'typeOfCommunication',
          options: { unique: false }
        },
        {
          keypath: 'location',
          name: 'location',
          options: { unique: false }
        },
        {
          keypath: 'reason',
          name: 'reason',
          options: { unique: false }
        },
        {
          keypath: 'area',
          name: 'area',
          options: { unique: false }
        },
        {
          keypath: 'description',
          name: 'description',
          options: { unique: false }
        },
        {
          keypath: 'mitigatingActions',
          name: 'mitigatingActions',
          options: { unique: false }
        },
        {
          keypath: 'communicationProcedure',
          name: 'communicationProcedure',
          options: { unique: false }
        },
        {
          keypath: 'contact',
          name: 'contact',
          options: { unique: false }
        },
        {
          keypath: 'fireWatch',
          name: 'fireWatch',
          options: { unique: false }
        },
        {
          keypath: 'hazardDescription',
          name: 'hazardDescription',
          options: { unique: false }
        },
        {
          keypath: 'name',
          name: 'name',
          options: { unique: false }
        },
        {
          keypath: 'safetyPermitType',
          name: 'safetyPermitType',
          options: { unique: false }
        },
        {
          keypath: 'createdByUserId',
          name: 'createdByUserId',
          options: { unique: false }
        },
        {
          keypath: 'createdDate',
          name: 'createdDate',
          options: { unique: false }
        },
        {
          keypath: 'activeRecord',
          name: 'activeRecord',
          options: { unique: false }
        },
        {
          keypath: 'currentpermitstatusid',
          name: 'currentpermitstatusid',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusid',
          name: 'currentstatusid',
          options: { unique: false }
        },
        {
          keypath: 'currentpermitstatusactionid',
          name: 'currentpermitstatusactionid',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionid',
          name: 'currentstatusactionid',
          options: { unique: false }
        },
        {
          keypath: 'currentpermitstatusactionstatusid',
          name: 'currentpermitstatusactionstatusid',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionstatusid',
          name: 'currentstatusactionstatusid',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusname',
          name: 'currentstatusname',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusdate',
          name: 'currentstatusdate',
          options: { unique: false }
        },
        {
          keypath: 'currentstatususerfullname',
          name: 'currentstatususerfullname',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusreason',
          name: 'currentstatusreason',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionname',
          name: 'currentstatusactionname',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactiondate',
          name: 'currentstatusactiondate',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionuserfullname',
          name: 'currentstatusactionuserfullname',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionreason',
          name: 'currentstatusactionreason',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionstatusname',
          name: 'currentstatusactionstatusname',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionstatusdate',
          name: 'currentstatusactionstatusdate',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionstatususerfullname',
          name: 'currentstatusactionstatususerfullname',
          options: { unique: false }
        },
        {
          keypath: 'currentstatusactionstatusreason',
          name: 'currentstatusactionstatusreason',
          options: { unique: false }
        }
      ]
    },
    {
      store: 'requests',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: true
      },
      storeSchema: [
        {
          name: 'requestTime',
          keypath: 'requestTime',
          options: { unique: true }
        },
        {
          name: 'method',
          keypath: 'method',
          options: { unique: false }
        },
        {
          name: 'url',
          keypath: 'url',
          options: { unique: false }
        },
        {
          name: 'body',
          keypath: 'body',
          options: { unique: false }
        }
      ]
    },
    {
      store: 'tableStates',
      storeConfig: {
        keyPath: 'id',
        autoIncrement: true
      },
      storeSchema: [
        {
          name: 'connectionStringName',
          keypath: 'connectionStringName',
          options: { unique: false }
        },
        {
          name: 'facilityId',
          keypath: 'facilityId',
          options: { unique: false }
        },
        {
          name: 'isStandard',
          keypath: 'isStandard',
          options: { unique: false }
        },
        {
          name: 'permitType',
          keypath: 'permitType',
          options: { unique: false }
        },
        {
          name: 'page',
          keypath: 'page',
          options: { unique: false }
        },
        {
          name: 'sorts',
          keypath: 'sorts',
          options: { unique: false }
        },
        {
          name: 'filters',
          keypath: 'filters',
          options: { unique: false }
        },
        {
          name: 'filterGroupId',
          keypath: 'filterGroupId',
          options: { unique: false }
        }

      ]
    }
  ]
};
