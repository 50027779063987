import { Injectable } from '@angular/core';
import { State, StateReplay } from '@devlime/ngx-state';
import { Observable } from 'rxjs';
import { Language } from '~translations/models/language.model';

interface TranslationsStateData {
  readonly defaultTranslations: any;
  readonly translations: any;
  readonly activeLanguage: Language;
  readonly defaultLanguage: Language;
  readonly languages: Language[];
}

@Injectable({
  providedIn: 'root'
})
export class TranslationsState extends State<TranslationsStateData> {

  @StateReplay<TranslationsState, any>()
  readonly defaultTranslations: any;
  readonly defaultTranslations$: Observable<any>;

  @StateReplay<TranslationsState, Language>()
  readonly activeLanguage: Language;
  readonly activeLanguage$: Observable<Language>;

  @StateReplay<TranslationsState, Language>()
  readonly defaultLanguage: Language;
  readonly defaultLanguage$: Observable<Language>;

  @StateReplay<TranslationsState, Language[]>()
  readonly languages: Language[];
  readonly languages$: Observable<Language[]>;

  @StateReplay<TranslationsState, any>()
  readonly translations: any;
  readonly translations$: Observable<any>;

  constructor() {
    super();
  }
}
