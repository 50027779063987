import { Injectable } from '@angular/core';
import { State, StateReplay } from '@devlime/ngx-state';
import { Dictionary } from 'lodash';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';

export interface AssetSettings {
  enforceUniqueCode: boolean;
  visibleColumns: string[];
}

export interface ComponentSettings {
  enforceUniqueCode: boolean;
  masterListSelections: string[];
  requiredFields: string[];
  visibleColumns: string[];
}

export interface MasterlistSettings {
  enabledFields: Dictionary<boolean>;
}

export interface GeneralTabSettings {
  enabledFields: Dictionary<boolean>;
}

export interface HazardAnalysisTabSettings {
  isChecked?: string[];
}

export type PermitEditTabSettings = GeneralTabSettings | HazardAnalysisTabSettings;

export interface PermitEditSettings {
  defaultTab: string;
  headerFieldsEnabled: Dictionary<boolean>;
  tabOrder: Dictionary<any>;
  tabs: Dictionary<PermitEditTabSettings>;
}

export interface PermitIndexSettings {
  statusColors: Dictionary<string>;
  visibleColumns: string[];
}

export interface PermitPrintingSettings {
  defaultPrintList?: string;
  enabledPrintLists?: string[];
}

export interface PermitStatusSettings {
  autoAdvance?: string[];
  disableStatusDuringAction?: Dictionary<boolean>;
  enabled?: boolean;
  sameUserCannot?: boolean;
}

export interface PermitActionSettings {
  enabled: boolean;
}

export interface PermitActionStatusSettings {
  beginsOn?: string;
  enabled?: boolean;
  endsOn?: string;
  passwordEnabled?: boolean;
  reasonEnabled?: boolean;
  revertsOn?: string;
}

export interface PermitSettings {
  PermitCreate: any;
  PermitEdit: PermitEditSettings;
  PermitIndex: PermitIndexSettings;
  Permit: PermitPrintingSettings;
  PermitStatus: PermitStatusSettings;
  PermitStatusAction: PermitActionSettings;
  PermitStatusActionStatus: PermitActionStatusSettings;
}

export interface FacilityData {
  readonly allSettings: Dictionary<any>[];
  readonly assetSettings: AssetSettings;
  readonly componentSettings: ComponentSettings;
  readonly dashboardIcons: Dictionary<string>;
  readonly details: any[];
  readonly generalSettings: Dictionary<any>;
  readonly methods: any[];
  readonly mitigation: any[];
  readonly moduleSettings:any;
  readonly notes: any[];
  readonly noteTypes: Dictionary<any[]>;
  readonly permitCreateSettings: any;
  readonly permitGroups: Dictionary<any[]>;
  readonly permitIndexSettings: PermitIndexSettings;
  readonly permitSettings: Dictionary<any>;
  readonly permitTypes: string[];
  readonly printManageSettings: any;
  readonly standardSettings: Dictionary<PermitSettings>;
  readonly statuses: Dictionary<any[]>;
  readonly statusActions: Dictionary<any[]>;
  readonly statusActionStatuses: Dictionary<any[]>;
  readonly varietyTypes: Dictionary<any[]>;
}

export type FacilityDataKey = keyof FacilityData;

@Injectable({
  providedIn: 'root'
})
export class FacilityState extends State<FacilityData> {

  @StateReplay<FacilityState, AssetSettings>()
  private allSettings: Dictionary<any>[];
  readonly allSettings$: Observable<Dictionary<any>[]>;

  @StateReplay<FacilityState, AssetSettings>()
  private assetSettings: AssetSettings;
  readonly assetSettings$: Observable<AssetSettings>;

  @StateReplay<FacilityState, ComponentSettings>()
  private componentSettings: ComponentSettings;
  readonly componentSettings$: Observable<ComponentSettings>;

  @StateReplay<FacilityState, Dictionary<string>>()
  private dashboardIcons: Dictionary<string>;
  readonly dashboardIcons$: Observable<Dictionary<string>>;

  @StateReplay<FacilityState, any[]>()
  private details: any[];
  readonly details$: Observable<any[]>;

  @StateReplay<FacilityState, Dictionary<any>>()
  private generalSettings: Dictionary<any>;
  readonly generalSettings$: Observable<Dictionary<any>>;

  @StateReplay<FacilityState, any>()
  private moduleSettings: Dictionary<any>;
  readonly moduleSettings$: Observable<Dictionary<any>>;

  @StateReplay<FacilityState, any[]>()
  private notes: any[];
  readonly notes$: Observable<any[]>;

  @StateReplay<FacilityState, any[]>()
  private methods: any[];
  readonly methods$: Observable<any[]>;

  @StateReplay<FacilityState, any[]>()
  private mitigation: any[];
  readonly mitigation$: Observable<any[]>;

  @StateReplay<FacilityState, Dictionary<any[]>>()
  private noteTypes: Dictionary<any[]>;
  readonly noteTypes$: Observable<Dictionary<any[]>>;

  @StateReplay<FacilityState, Dictionary<any>[]>()
  private permitGroups: Dictionary<any>[];
  readonly permitGroups$: Observable<Dictionary<any>[]>;

  @StateReplay<FacilityState, PermitIndexSettings>()
  private permitIndexSettings: PermitIndexSettings;
  readonly permitIndexSettings$: Observable<PermitIndexSettings>;

  @StateReplay<FacilityState, PermitPrintingSettings>()
  private permitPrintingSettings: PermitPrintingSettings;
  readonly permiPrintingtSettings$: Observable<PermitPrintingSettings>;

  @StateReplay<FacilityState, Dictionary<any>>()
  private permitSettings: Dictionary<any>;
  readonly permitSettings$: Observable<Dictionary<any>>;

  @StateReplay<FacilityState, string[]>()
  private permitTypes: string[];
  readonly permitTypes$: Observable<string[]>;

  @StateReplay<FacilityState, any>()
  private printManageSettings: any;
  readonly printManageSettings$: Observable<any>;

  @StateReplay<FacilityState, Dictionary<any>>()
  private standardSettings: Dictionary<any>;
  readonly standardSettings$: Observable<Dictionary<any>>;

  @StateReplay<FacilityState, Dictionary<any[]>>()
  private statuses: Dictionary<any[]>;
  readonly statuses$: Observable<Dictionary<any[]>>;

  @StateReplay<FacilityState, Dictionary<any[]>>()
  private statusActions: Dictionary<any[]>;
  readonly statusActions$: Observable<Dictionary<any[]>>;

  @StateReplay<FacilityState, Dictionary<any[]>>()
  private statusActionStatuses: Dictionary<any[]>;
  readonly statusActionStatuses$: Observable<Dictionary<any[]>>;

  @StateReplay<FacilityState, Dictionary<any[]>>()
  private varietyTypes: Dictionary<any[]>;
  readonly varietyTypes$: Observable<Dictionary<any[]>>;

  constructor(
    private indexedDB: NgxIndexedDBService
  ) {
    super();
  }

  set(prop: FacilityDataKey, value: FacilityData[FacilityDataKey]): void {
    super.set(prop, value);

    if (prop && value) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }

      if (prop !== 'allSettings') {
        sessionStorage.setItem(prop, value);
      }

      this.indexedDB.update('facilityState', {
        key: prop,
        value
      }).subscribe();
    }
  }
}
