import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import { get } from 'lodash';
import { FunctionsEnabled } from '~shared/models/functions-enabled.interface';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: [ './context-menu.component.scss' ]
})
export class ContextMenuComponent {
  @Input() x = 0;
  @Input() y = 0;
  @Input() CRUDConfig;
  @Input() data;
  @Input() functionsEnabled: FunctionsEnabled;
  @Input() isShow: boolean;
  @Output() addEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() customEvent = new EventEmitter();

  constructor() { }

  create(e): void {
    this.addEvent.emit(e);
  }

  edit(e): void {
    this.editEvent.emit(e);
  }

  delete(e): void {
    this.deleteEvent.emit(e);
  }

  customAction(event, action) {
    this.customEvent.emit({
      event,
      action
    });
  }

  isFunctionEnabled(fnName: string): boolean {
    const crudFnNameMap = {
      add: 'create'
    };

    const functionEnabled = get(this.functionsEnabled, fnName);

    const crudFnName = crudFnNameMap[fnName] ? crudFnNameMap[fnName] : fnName;
    const crudConfigEnabled = get(this.CRUDConfig, `${crudFnName}.enabled`);

    return functionEnabled || crudConfigEnabled;
  }
}
