import {
  Pipe, PipeTransform
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'domSanitize',
  standalone: true
})
export class DomSanitizePipe implements PipeTransform {
  constructor(protected domSanitizer: DomSanitizer) {}

  public transform(value: any): any {
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }
}
