import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  attachedPermit = new BehaviorSubject(null);
  refreshAfterReorder = new Subject();

  deSelectAll = new Subject();
  $deSelectAll = this.deSelectAll.asObservable();

  constructor() {}
}
