import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit
} from '@angular/core';
import {
  AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { columnsTotalWidth } from '@swimlane/ngx-datatable';
import { find } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '~auth/services/auth.service';
import { UI_BUILD_VERSION } from '~core/constants/version';
import { AppState } from '~core/states/app/app.state';
import { STILFacilityDataService } from '~shared/services/apiSTILFacilityController';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@azure/storage-blob';
import { SAMLDataService } from '~shared/services/apiSAMLController';
import { WINDOW } from '~core/services/window/window.service';
import { environment } from 'src/environments/environment';
import { AuthState } from '~auth/states/auth.state';
import { of } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent implements OnInit {
  uiVersion = UI_BUILD_VERSION;
  connectionStringNames: any[];
  facilities: any[];
  idpSettings: any;
  useSSO = false;
  stilUseSSO = false;
  apiRedirect: string;
  filteredUsers;
  loginForm: UntypedFormGroup;
  loading = false;
  returnUrl: string;
  showInvalidCredsMsg = false;
  submitted = false;
  flag: string;
  ssoToken: string;
  loggedOut: boolean;
  stilLoggingIn: boolean;
  loggedInViaSSO = false;

  get connectionStringNameCtrl(): AbstractControl {
    return this.loginForm.get('connectionStringName');
  }

  get facilityIdCtrl(): AbstractControl {
    return this.loginForm.get('facilityId');
  }

  get passwordCtrl(): AbstractControl {
    return this.loginForm.get('password');
  }

  get usernameCtrl(): AbstractControl {
    return this.loginForm.get('username');
  }

  constructor(
    private appState: AppState,
    private authSvc: AuthService,
    private formBuilder: UntypedFormBuilder,
    private facDataSvc: STILFacilityDataService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private http: HttpClient,
    private samlDataSvc: SAMLDataService,
    @Inject(WINDOW) private window: Window,
    private state: AuthState
  ) {}

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      connectionStringName: [ '', Validators.required ],
      facilityId: [ '', Validators.required ],
      username: [ '' ],
      password: [ '' ]
    });

    this.connectionStringNameCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap((connectionStringName: string) => {
          if((!!this.useSSO || !!this.stilUseSSO) && !!this.ssoToken)
          {
            return this.facDataSvc.getActiveFacilitiesForSSOUser(this.ssoToken, connectionStringName);
          } else {
            return this.facDataSvc.getRecords(true, connectionStringName);
          }

        })
      )
      .subscribe((facilities) => {
        this.facilities = facilities;

        if (facilities.length) {
          const defaultFacility = facilities[0];
          this.loginForm.patchValue({ facilityId: defaultFacility.ID });
          this.cdr.markForCheck();
        } else {
          this.toastr.error('No Facilities were found. Please try another Connection String Name.');
        }
      });

    this.passwordCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(pw => this.showInvalidCredsMsg = false);

    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(({
        loginPageData: {
          connectionStringNames, defaultConnectionStringName, defaultFacility, facilities, idpSettings, users
        }
      }) => {
        this.connectionStringNames = connectionStringNames;
        this.facilities = facilities;
        this.idpSettings = idpSettings;
        this.apiRedirect = this.idpSettings.SPAPIRedirect;
        this.ssoToken = this.route.snapshot.queryParams.Token;
        this.flag = this.route.snapshot.queryParams.flag;
        this.useSSO = this.idpSettings?.UseSSO && !this.flag;
        this.stilUseSSO = this.idpSettings?.STILUseSSO;
        this.stilLoggingIn = this.stilUseSSO && (this.flag == "SSOSafeTKPro" || !!this.ssoToken);
        this.loggedInViaSSO = this.appState.get('loggedInViaSSO');

        this.loggedOut = this.state.get('loggedOut');

        this.loginForm.patchValue({
          connectionStringName: defaultConnectionStringName,
          facilityId: +defaultFacility.ID
        }, { emitEvent: false });
      });
  }

  onSubmit() {
    const {
      connectionStringName, facilityId, password, username
    } = this.loginForm.value;
    this.submitted = true;
    this.loading = true;

    const selectedFacility = find(this.facilities, { ID: +facilityId });

    if (((this.loginForm.invalid || !this.ssoToken) && (this.useSSO || this.stilLoggingIn)) || ((this.loginForm.invalid || !username) && !(this.useSSO || this.stilLoggingIn))) {
      this.toastr.error('Invalid user for this facility');
      this.loading = false;
      return;
    }

    of(!!this.useSSO || !!this.stilLoggingIn)
      .pipe(
        switchMap(useSSO => {
          if (useSSO) {
            this.appState.set('loggedInViaSSO', true);
            return this.samlDataSvc.ssoLogin(connectionStringName, facilityId, this.ssoToken);
          }
          return this.authSvc.login(connectionStringName, facilityId, username, password);
        })
      )
      .subscribe(authObj => {
        if (authObj) {
          this.appState.set('facility', selectedFacility);
          this.showInvalidCredsMsg = false;
          const { user } = authObj;
          this.appState.set('user', user);
          this.authSvc.setSession(authObj);
          this.authSvc.redirectUser();
        } else {
          this.resetLoginForm();
        }
      });
  }

  private resetLoginForm(): void {
    this.loginForm.patchValue({
      username: '',
      password: ''
    });
    this.submitted = false;
    this.loading = false;
    this.showInvalidCredsMsg = true;
    this.cdr.markForCheck();
  }

  startOver(): void {
    this.appState.set('loggedInViaSSO', false);
    this.window.location.href = this.apiRedirect;
  }
}


