import {
  NgModule, Pipe, PipeTransform 
} from '@angular/core';
import startCase from 'lodash-es/startCase';

@Pipe({
  name: 'startCase'
})
export class StartCasePipe implements PipeTransform {
  transform(value: string): string {
    return startCase(value);
  }
}

@NgModule({
  declarations: [ StartCasePipe ],
  exports: [ StartCasePipe ]
})
export class StartCasePipeModule {}

