
export const environment = {
  production: 'false',
  BUILD_ENV: 'dev',
  API_URL: 'https://demoapi.safetk.dev/',
  APP_INSIGHTS_KEY: '',
  AZURE_STORAGE_HOSTNAME: 'https://vmstg1901storage.blob.core.windows.net',
  AZURE_CONTAINER_NAME: 'test-uploads',
  SIGNALR_URL: 'https://demoapi.safetk.dev/signalr'
};
