import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Dictionary, includes, without 
} from 'lodash';
import { LayoutService } from '~core/services/layout/layout.service';
import { SettingsService } from '~core/services/settings/settings.service';
import { FacilityState } from '~core/states/facility/facility.state';
import { SidebarState } from '~core/states/sidebar/sidebar.state';

import { AppState } from '../../states/app/app.state';

export type DashboardRoute = '/' | '/dashboards' | '/announcements';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  @Input() isMobile = false;

  @Input() visibility = 'show';

  @HostBinding('class.mobile') get mobileClass(): boolean {
    return this.isMobile;
  }

  @HostListener('window:click', [ '$event' ]) onClick(event: PointerEvent): void {
    const clickPosition = event.clientX;

    if (this.isMobile && clickPosition > 250) {
      this.state.set('collapsed', true);
    }
  }

  collapsed$ = this.state.collapsed$;
  dashboardRoute: DashboardRoute = '/dashboards';
  menuItems = [];

  navGroups$ = this.state.navLinks$;
  openGroups: string[] = [];
  isMobileLayout = false;

  get facilityId(): number {
    return this.appState.get('facilityId');
  }

  constructor(
    private appState: AppState,
    private state: SidebarState,
    private layoutService: LayoutService,
    private settingSvc: SettingsService,
    private facilityState: FacilityState,
    private router: Router
  ) {}

  ngOnInit() {

    let canCustomDashboard = this.facilityState.get('moduleSettings').CustomDashboards;
    this.dashboardRoute = canCustomDashboard ? '/dashboards' : '/announcements';

    this.state.dashboardRouted$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.closeAllGroups());

    this.layoutService.isMobile$.pipe(
      untilDestroyed(this)
    ).subscribe((isMobile: boolean) => {
      this.isMobileLayout = isMobile;
      if (this.isMobileLayout) {
        this.closeAllGroups();
      }
    })


  }

  toggleGroup({ ID }: Dictionary<any>): void {
    if (this.openGroups.includes(ID)) {
      this.openGroups = without(this.openGroups, ID);
    } else {
      if (this.isMobileLayout) {
        this.closeAllGroups();
      }
      this.openGroups.push(ID);
    }
  }

  isActive({ PermitGroupID }): boolean {
    const isPermitLink = this.state.get('activePermitGroup');
    if (isPermitLink) {
      return this.state.get('activePermitGroup') === PermitGroupID;
    }
  }

  dashboardsActive(route: any): boolean {
    return this.router.url.includes(route);
  }

  isOpen(id): boolean {
    return includes(this.openGroups, id);
  }

  closeAllGroups(): void {
    this.openGroups = [];
  }
}
