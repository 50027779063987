<div class="btn-group mr-2">
  <button
    mat-button
    [matMenuTriggerFor]="menu"
    id="user-menu-button"
    class="w-full"
  >
    <div
      class="w-[45px] h-[45px] p-2"
      [innerHtml]="svg | domSanitize"
    ></div>

    <ng-container *ngIf="!iconOnly">
      <span class="username pr-2">
        {{ user?.FirstName }}
        {{ user?.LastName }}
      </span>
      <i class="fas fa-caret-down m-2"></i>
    </ng-container>
  </button>
</div>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    [matMenuTriggerFor]="langs"
  >
    <i class="fas fa-language fa-lg mr-2"></i>
    Language
  </button>

  <a
    *ngIf="!isActiveDirectory"
    style="text-decoration: none"
    mat-menu-item
    (click)="resetPassword()"
  >
    <i class="fas fa-key fa-lg mr-2"></i>
    Reset Password
  </a>

  <a
    *ngIf="infoUrl?.length"
    style="text-decoration: none"
    mat-menu-item
    (click)="goToLink()"
  >
    <i class="fas fa-info fa-lg ml-1 mr-3"></i>
    Information
  </a>

  <a
    *ngIf="isMobile$ | async"
    style="text-decoration: none"
    mat-menu-item
    (click)="toggleAdminToolbar()"
  >
    <i class="fa fa-angle-double-left fa-lg ml-1 mr-3"></i>
    Toggle Admin Bar
  </a>

  <a
    style="text-decoration: none"
    mat-menu-item
    (click)="logout()"
  >
    <i class="fas fa-sign-out-alt fa-lg mr-2"></i>
    Sign Out
  </a>
</mat-menu>
<mat-menu #langs="matMenu">
  <button
    mat-menu-item
    *ngFor="let language of languages$ | async"
    (click)="selectLanguage(language)"
  >
    {{ language.Name }}
    <i
      *ngIf="(activeLanguage$ | async)?.ID === language?.ID"
      class="fas fa-check"
    ></i>
  </button>
</mat-menu>
