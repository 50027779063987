import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER, ErrorHandler, Injector, NgModule 
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { DialogModule } from '@ngneat/dialog';
import { EditableModule } from '@ngneat/edit-in-place';
import {
  popperVariation, TippyModule, tooltipVariation 
} from '@ngneat/helipopper';
import { HotToastModule } from '@ngneat/hot-toast';
import { HighchartsChartModule } from 'highcharts-angular';
import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { DEFAULT_DIALOG_OPTIONS } from '~core/constants/default-dialog-options.constant';
import { ApiUrlInterceptorService } from '~core/interceptors/api-url-interceptor.service';
import { NullPatchInterceptor } from '~core/interceptors/null-patch.interceptor';
import { AppService, InitializeAppState } from '~core/services/app/app.service';
import { InitializeLoading } from '~core/services/loading/loading.service';
import { InitializePermitEditState, PermitEditState } from '~permit/permit-edit/state/permit-edit.state';
import { TranslatePipe } from '~shared/pipes/translate.pipe';
import { TranslationsModule } from '~translations/translations.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { DateModule } from './date/date.module';
import { DefaultPageComponent } from './default-page/default-page.component';
import { OfflineModule } from './offline/offline.module';
import { PermissionsModule } from './permissions/permissions.module';
import { SignalrModule } from './signalr/signalr.module';

@NgModule({
  declarations: [ AppComponent, DefaultPageComponent ],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    DialogModule.forRoot(),
    DateModule,
    FormsModule,
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot({ ttl: 150000 }),
    MatBottomSheetModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatIconModule,
    MatInputModule,
    PermissionsModule,
    QuillModule.forRoot({
      theme: 'snow',
      modules: {
        imageResize: {
          modules: [ 'Resize', 'DisplaySize' ]
        }
      }
    }),
    ReactiveFormsModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation
      }
    }),
    ToastrModule.forRoot(),
    TranslationsModule,
    SignalrModule,
    HotToastModule.forRoot({
      dismissible: true,
      position: 'top-right'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    EditableModule,
    OfflineModule,
    HighchartsChartModule
  ],
  providers: [
    TranslatePipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        closeOnNavigation: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NullPatchInterceptor,
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: DEFAULT_DIALOG_OPTIONS
    },
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: InitializePermitEditState,
      deps: [ PermitEditState ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeLoading,
      deps: [ Injector ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeAppState,
      deps: [ AppService ],
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

