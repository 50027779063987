import { Injectable } from '@angular/core';
import { HttpCacheManager } from '@ngneat/cashew';
import {
  camelCase, capitalize, chain, concat, Dictionary, groupBy, head, last, mapValues, partition, reduce 
} from 'lodash';
import _map from 'lodash-es/map';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {
  forkJoin, from, Observable 
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NetworkState } from '~auth/states/network-state/network.state';
import { AppState } from '~core/states/app/app.state';
import {
  FacilityDataKey,
  FacilityState,
  MasterlistSettings,
  PermitPrintingSettings
} from '~core/states/facility/facility.state';
import { FacilitySetting } from '~shared/models/facility-setting.model';
import { PermitEditSettings, PermitSettingsObject } from '~shared/models/permit-edit-settings.model';
import { FacilitySettingSavedPermitDataService } from '~shared/services/apiFacilitySettingSavedPermitController';
import { MasterListDataService } from '~shared/services/apiMasterListController';
import { GeneralAppDataService } from '~shared/services/app-services/apiGeneralAppController';

import { SETTING_NAME_MAP, SETTING_VALUE_MAP } from '../../constants/facility-setting-maps.constant';

@Injectable({
  providedIn: 'root'
})
export class FacilitySettingService {

  private facilityStateDataKeys = [
    'allSettings',
    'assetSettings',
    'componentSettings',
    'dashboardIcons',
    'generalSettings',
    'noteTypes',
    'permitCreateSettings',
    'permitGroups',
    'permitIndexSettings',
    'permitSettings',
    'permitTypes',
    'standardSettings',
    'statuses',
    'statusActions',
    'statusActionStatuses',
    'varietyTypes'
  ];

  get facilityId(): number {
    return this.appState.get('facilityId');
  }

  constructor(
    private appState: AppState,
    private cacheManager: HttpCacheManager,
    private generalAppDataSvc: GeneralAppDataService,
    private indexedDB: NgxIndexedDBService,
    private masterlistSvc: MasterListDataService,
    private networkState: NetworkState,
    private savedPermitFacilitySettingSvc: FacilitySettingSavedPermitDataService,
    private state: FacilityState
  ) { }

  initFacilityState(): Observable<any> {
    if (this.networkState.get('isOnline')) {
      /* Gets, parses, and sets all facility settings to facility state */
      this.cacheManager.delete('facility-settings');
      this.cacheManager.delete('permit-facility-settings');

      return forkJoin([
        this.generalAppDataSvc.getEnabledPermitNames(),
        this.masterlistSvc.getRecordsForParentID('PermitGroup', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('VarietyType', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('Status', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('StatusAction', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('StatusActionStatus', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('NoteType', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('Notes', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('Methods', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('Mitigation', this.facilityId, true, true),
        this.masterlistSvc.getRecordsForParentID('Details', this.facilityId, true, true),
        this.savedPermitFacilitySettingSvc.getRecordsForFacilityID('STIL:FacilitySettingSaved', this.facilityId, 'facility-settings'),
        this.savedPermitFacilitySettingSvc.getRecordsForFacilityID('FacilitySettingSavedPermit', this.facilityId, 'permit-facility-settings')
      ])
        .pipe(
          map(([
            permitTypes,
            permitGroups,
            varietyTypes,
            statuses,
            statusActions,
            statusActionStatuses,
            noteTypes,
            notes,
            methods,
            mitigation,
            details,
            generalSettings,
            allPermitSettings
          ]) => {
            this.state.set('permitGroups', groupBy(permitGroups, 'ItemType'));
            this.state.set('permitTypes', _map(permitTypes, 'TableName'));
            this.state.set('statuses', groupBy(statuses, 'ItemType'));
            this.state.set('statusActions', groupBy(statusActions, 'ItemType'));
            this.state.set('statusActionStatuses', groupBy(statusActionStatuses, 'ItemType'));
            this.state.set('methods', groupBy(methods, 'ItemType'));
            this.state.set('mitigation', groupBy(mitigation, 'ItemType'));
            this.state.set('details', groupBy(details, 'ItemType'));
            this.state.set('notes', groupBy(notes, 'ItemType'));
            this.state.set('noteTypes', groupBy(noteTypes, 'ItemType'));
            this.state.set('varietyTypes', groupBy(varietyTypes, 'ItemType'));

            this.state.set('allSettings', concat(generalSettings, allPermitSettings));
            const parsedGeneralSettings = this.parseGeneralSettings(generalSettings);
            const parsedPermitSettings = partition(allPermitSettings, { IsStandard: true })
              .map(settingGroups => this.parsePermitSettings(settingGroups));

            return {
              generalSettings: parsedGeneralSettings,
              allPermitSettings: parsedPermitSettings
            };
          }),
          tap(({ generalSettings, allPermitSettings: [ standardSettings, permitSettings ] }) => {
            const {
              AssetStructure = {}, Dashboard = {}, General = {}, Module={}, PrintManage={}
            } = generalSettings;

            this.state.set('assetSettings', AssetStructure?.Asset ?? {});
            this.state.set('componentSettings', AssetStructure?.Component ?? {});
            this.state.set('dashboardIcons', Dashboard?.icons ?? {});
            this.state.set('generalSettings', General ?? {});
            this.state.set('moduleSettings', Module ?? {});
            this.state.set('permitIndexSettings', permitSettings.PermitIndex);
            this.state.set('permitSettings', permitSettings);
            this.state.set('printManageSettings', PrintManage);
            this.state.set('standardSettings', standardSettings);

            if (PrintManage.defaultPrintProfile) {
              const activePrintProfile = sessionStorage.getItem('printProfileName') || PrintManage.defaultPrintProfile;
              sessionStorage.setItem('printProfileName', activePrintProfile);
            }
          })
        );
    } else {
      return from(this.loadsessionStorage());
    }
  }

  async loadsessionStorage(): Promise<void> {
    for (let key of this.facilityStateDataKeys) {
      let value = sessionStorage.getItem(key);

      if (!this.networkState.get('isOnline') && !value) {
        const cachedItem: any = await this.indexedDB.getByIndex('facilityState', 'key', key).toPromise();
        value = cachedItem?.value;
      }

      if (value) {
        this.state.set(key as FacilityDataKey, JSON.parse(value));
      }
    }
  }

  parseGeneralSettings(generalSettings: any[]): Dictionary<any> {
    return chain(generalSettings)
      .groupBy(({ UniqueName }) => head(UniqueName.split('_')))
      .mapValues((groupedSettings, featureName) => {
        if (featureName === 'AssetStructure') {
          return mapValues(groupBy(groupedSettings, ({ UniqueName }) => UniqueName.split('_')[1]),
            (assetStructureSettings ) => this.parseFinalSettingObject(assetStructureSettings, featureName));
        }

        // TODO: good enough for now
        if (featureName === 'Module') {
          return mapValues(groupBy(groupedSettings, ({ Attribute }) => Attribute?.replace(/\s/g, "")),
            ( moduleSettings ) => this.parseFinalSettingObject(moduleSettings, featureName));
        }

        return this.parseFinalSettingObject(groupedSettings, featureName);
      })
      .value();
  }


  /* TODO: Make this recursive */
  /* TODO: Test swapping out chain in favor of flow */
  parsePermitSettings(permitSettings: any): any {
    return chain(permitSettings)
      .groupBy(({ UniqueName }) => head(UniqueName.split('_')))
      .mapValues((featureGroupSettings, featureName) => {

        return chain(featureGroupSettings).groupBy(({ ItemType }) => ItemType)
          .mapValues((permitTypeGroupSettings) => {

            return chain(permitTypeGroupSettings).groupBy(({ VarietyTypeID }) => this.groupByOptionalProp(VarietyTypeID))
              .mapValues((varietyTypeGroupSettings, varietyTypeId) => {
                if (varietyTypeId === 'settings') {
                  return this.parseFinalSettingObject(varietyTypeGroupSettings, featureName);
                }

                return chain(varietyTypeGroupSettings).groupBy(({ StatusID }) => this.groupByOptionalProp(StatusID))
                  .mapValues((statusGroupSettings, statusId) => {
                    if (statusId === 'settings') {
                      return this.parseFinalSettingObject(statusGroupSettings, featureName);
                    }

                    return chain(statusGroupSettings)
                      .groupBy(({ StatusActionID }) => this.groupByOptionalProp(StatusActionID))
                      .mapValues((statusActionGroupSettings, actionId) => {
                        if (actionId === 'settings') {
                          return this.parseFinalSettingObject(statusActionGroupSettings, featureName);
                        }

                        return chain(statusActionGroupSettings)
                          .groupBy(({ StatusActionStatusID }) => this.groupByOptionalProp(StatusActionStatusID))
                          .mapValues((statusActionStatusGroupSettings, statusActionStatusId) => {
                            if (statusActionStatusId === 'settings') {
                              return this.parseFinalSettingObject(statusActionStatusGroupSettings, featureName);
                            } else {
                              return { settings: this.parseFinalSettingObject(statusActionStatusGroupSettings, featureName) };
                            }
                          })
                          .value();
                      })
                      .value();
                  })
                  .value();
              })
              .value();
          })
          .value();
      })
      .value();
  }

  toSettingsObj(settings: FacilitySetting[], isBoolean = false): PermitSettingsObject {
    return reduce(settings, (settingsObj, { Attribute, SettingValue }: FacilitySetting) =>
      ({
        ...settingsObj,
        [Attribute]: (isBoolean ? !!SettingValue : SettingValue)
      })
    , {});
  }

  private groupBySettingName(tabSettings: FacilitySetting[]): Dictionary<any> {
    return groupBy(tabSettings, ({ UniqueName }) => {
      const settingName = last(UniqueName.split('_'));

      const keyName = SETTING_NAME_MAP[settingName] || settingName;

      return camelCase(keyName);
    });
  }

  private groupByOptionalProp(propValue: any, unsetGroupName = 'settings'): string | number {
    let groupKey: number | string = propValue;

    if (propValue === 0 || propValue === null) {
      groupKey = unsetGroupName;
    }

    return groupKey;
  }

  private groupByUniqueName(settings: FacilitySetting[]): any {
    return groupBy(settings, ({ UniqueName }) => {
      let groupKey = UniqueName
        .replace(/^(AssetStructure|CreatePermit|Dashboard|General|EditPermit|PermitIndex|PermitStatus|PermitStatusAction|PermitStatusActionStatus)_/, '')
        .replace('Tab_', '');

      if (groupKey.startsWith('Tab') && groupKey.includes('_')) {
        groupKey = camelCase(head(groupKey.split('_')).replace('Tab', ''));
      }

      groupKey = SETTING_NAME_MAP[groupKey] ?? camelCase(groupKey);

      return groupKey;
    });
  }

  private parseFinalSettingObject(settings: any[], featureName: string): Dictionary<any> {
    const groupedSettings = groupBy(settings, 'UniqueName');

    switch (featureName) {
    case 'AssetStructure':
    case 'CreatePermit':
    case 'Dashboard':
    case 'EditPermit':
    case 'General':
    case 'PermitIndex':
    case 'PermitStatus':
    case 'PrintManage':
    case 'PermitStatusAction':
    case 'PermitStatusActionStatus':
      return this.parsePermitEditSettings(settings);

    case 'MasterList':
      return this.parseMasterListSettings(groupedSettings);

    case 'Permit':
      return this.parsePermitPrintingSettings(groupedSettings);

    default:
      return settings;
    }
  }

  private parsePermitEditSettings(allSettings: FacilitySetting[], recurse = false): PermitEditSettings {
    const settingsObj = recurse
      ? this.groupBySettingName(allSettings)
      : this.groupByUniqueName(allSettings);

    return reduce(settingsObj, (parsedSettings, value, key) => {
      const valueType = SETTING_VALUE_MAP[key];

      switch (valueType) {
      case 'ATTRIBUTE':
        parsedSettings[key] = value?.[0]?.Attribute ?? value;
        break;

      case 'ATTRIBUTE_LIST':
        parsedSettings[key] = _map(value, 'Attribute');
        break;

      case 'BOOLEAN_ATTRIBUTE_OBJECT':
        parsedSettings[key] = reduce(value, (attrObj, { Attribute }) => ({
          ...attrObj,
          [Attribute]: true
        }), {});
        break;

      case 'BOOLEAN':
        parsedSettings[key] = true;
        break;

      case 'BOOLEAN_VALUE_OBJECT':
        parsedSettings[key] = this.toSettingsObj(value, true);
        break;

      case 'VALUE_NESTED_ATTRIBUTE_BOOLEAN_OBJECT':
        parsedSettings[key] = this.toNestedSettingsObjBool(value);
        break;

      case 'VALUE_NESTED_ATTRIBUTE_OBJECT':
        parsedSettings[key] = this.toNestedSettingsObj(value);
        break;

      case 'FUNCTIONS_ENABLED':
        parsedSettings[key] = this.toFunctionsEnabledObj(value);
        break;

      case 'TAB':
        (parsedSettings.tabs ??= {})[key] = this.parsePermitEditSettings(value, true);
        break;

      case 'VALUE':
        parsedSettings[key] = value?.[0]?.SettingValue ?? value;
        break;

      case 'VALUE_LIST':
        parsedSettings[key] = _map(value, 'SettingValue');
        break;

      case 'VALUE_OBJECT':
      default:
        parsedSettings[key] = this.toSettingsObj(value);
      }

      return parsedSettings;
    }, {} as any);
  }

  private parseMasterListSettings({ MasterList_Enabled }: Dictionary<FacilitySetting[]>): MasterlistSettings {
    return {
      enabledFields: this.toSettingsObj(MasterList_Enabled, true)
    };
  }

  private parsePermitPrintingSettings(settings: Dictionary<FacilitySetting[]>): PermitPrintingSettings {
    const { Permit_Printing_PrintListDefault, Permit_Printing_PrintListEnabled } = settings;

    const printSettings: PermitPrintingSettings = {
      enabledPrintLists: _map(Permit_Printing_PrintListEnabled, 'Attribute')
    };

    if (Permit_Printing_PrintListDefault) {
      printSettings.defaultPrintList = Permit_Printing_PrintListDefault?.[0]?.SettingValue;
    }

    return printSettings;
  }

  private toFunctionsEnabledObj(settings: FacilitySetting[]): Dictionary<boolean> {
    return reduce(settings, (settingsObj, { SettingValue, UniqueName }: FacilitySetting) => {

      const nameParts = UniqueName.split('_');

      let key = capitalize(last(nameParts).replace('Enabled', ''));

      if (nameParts.length > 3) {
        key = `${nameParts[nameParts?.length - 2]}${key}`;
      }

      return {
        ...settingsObj,
        [camelCase(key)]: SettingValue
      };
    }, {});
  }


  toNestedSettingsObjBool(settings: FacilitySetting[]): PermitSettingsObject {
    return reduce(settings, (settingsObj, { Attribute, SettingValue }: FacilitySetting) => {
      if (SettingValue !== 'true' && Attribute !== SettingValue) {
        if (settingsObj?.[SettingValue] !== true) {
          settingsObj[SettingValue] ??= {};
          settingsObj[SettingValue][Attribute] = true;
        }

        return settingsObj;
      }

      return {
        ...settingsObj,
        [Attribute]: true
      };
    }, {});
  }

  toNestedSettingsObj(settings: FacilitySetting[]): PermitSettingsObject {
    return reduce(settings, (settingsObj, {
      Attribute, SettingValue, Config
    }: FacilitySetting) => {
      if (SettingValue !== 'true' && Attribute !== SettingValue) {
        if (settingsObj?.[SettingValue] !== true) {
          settingsObj[SettingValue] ??= {};
          settingsObj[SettingValue][Attribute] = JSON.parse(Config);
        }

        return settingsObj;
      }

      return {
        ...settingsObj,
        [Attribute]: JSON.parse(Config)
      };
    }, {});
  }
}
