<div class="p-2 flex flex-col w-full h-full relative">
  <div
    *ngIf="enabled === false"
    class="disabled-overlay"
  ></div>
  <div class="data-grid-toolbar h-[35px] flex flex-row justify-between items-center">
    <span class="data-grid-title p-2">{{ translatePath + '.' + title | translate }}</span>

    <div class="data-grid-utility flex flex-row justify-end items-center">
      <i
        *ngIf="showImport && !(showEditForm || showAddForm)"
        placement="left"
        [tooltip]="translatePath + '.' + 'Import' | translate"
        (click)="importItems()"
        class="fas fa-folder-plus w-[35px]"
      ></i>

      <i
        *ngIf="showAllBtn && !showAllToggled"
        placement="left"
        [tooltip]="translatePath + '.' + 'Show All' | translate"
        (click)="showAllClicked()"
        class="fas fa-folder-plus w-[35px]"
      ></i>

      <i
        *ngIf="showAllBtn && showAllToggled"
        placement="left"
        [tooltip]="translatePath + '.' + 'Show Checked' | translate"
        (click)="showAllClicked()"
        class="fas fa-folder-minus w-[35px]"
      ></i>

      <i
        *ngIf="showCreate && !addOrEditFormActive"
        placement="left"
        [tooltip]="translatePath + '.' + getTooltip('Add') | translate"
        (click)="addItem()"
        class="fas fa-plus w-[35px]"
      ></i>

      <i
        *ngIf="addOrEditFormActive"
        placement="left"
        [tooltip]="translatePath + '.' + getTooltip('Cancel') | translate"
        (click)="cancelAction()"
        class="far fa-window-close w-[35px]"
      ></i>

      <i
        *ngIf="addOrEditFormActive"
        placement="left"
        [tooltip]="translatePath + '.' + getTooltip('Save') | translate"
        (click)="saveItem()"
        class="fas fa-save w-[35px]"
      ></i>

      <i
        *ngIf="showDelete && selected?.length && !(showEditForm || showAddForm)"
        placement="left"
        [tooltip]="translatePath + '.' + getTooltip('Delete') | translate"
        class="fas fa-minus w-[35px]"
        (click)="deleteItem()"
      ></i>

      <i
        *ngIf="showEdit && selected?.length && !(showEditForm || showAddForm)"
        placement="left"
        [tooltip]="translatePath + '.' + getTooltip('Edit') | translate"
        class="fas fa-edit w-[35px]"
        (click)="editItem()"
      ></i>

      <i
        *ngIf="showDeselector && selected?.length"
        placement="left"
        tooltip="Go Back"
        class="fas fa-backspace w-[35px]"
        (click)="deselect()"
      ></i>

      <i
        *ngIf="showReorder && !(showEditForm || showAddForm)"
        placement="left"
        [tooltip]="translatePath + '.' + 'Reorder List' | translate"
        class="fas fa-grip-lines w-[35px]"
        (click)="toggleReorder('TAGNUMBER')"
      ></i>

      <i
        *ngIf="showRestoration && !(showEditForm || showAddForm)"
        placement="left"
        [tooltip]="translatePath + '.' + 'Restoration List' | translate"
        class="fas fa-sort-numeric-down-alt w-[35px]"
        (click)="toggleReorder('RESTORATIONTAGNUMBER')"
      ></i>

      <i
        *ngIf="!showAddForm && !showEditForm"
        placement="left"
        [tooltip]="translatePath + '.' + 'Filter' | translate"
        (click)="toggleFilters()"
        class="fas fa-filter w-[35px]"
      ></i>
    </div>
  </div>
  <ngx-datatable
    class="material"
    #myTable
    [columns]="columns"
    [columnMode]="ColumnMode[columnMode]"
    [style.height.px]="pageSize * 30 + 120"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    [rows]="rows"
    [rowClass]="getRowClass"
    [selected]="selected"
    [headerHeight]="showFilters || showAddForm ? '100' : '50'"
    [selectionType]="SelectionType[selectionType]"
    [footerHeight]="50"
    [loadingIndicator]="!rows || loading"
    [limit]="pageSize"
    [rowHeight]="rowHeight"
    [messages]="messages"
    [scrollbarH]="true"
    [scrollbarV]="false"
    sortType="single"
  >
    <!-- (tableContextmenu)="onTableContextMenu($event)" -->

    <!-- Row Detail Template -->
    <ngx-datatable-row-detail
      *ngIf="rowDetailTemplate"
      [template]="rowDetailTemplate"
      [rowHeight]="rowDetailHeight"
      (toggle)="onDetailToggle($event)"
    ></ngx-datatable-row-detail>
    <!-- Column Templates -->
    <ngx-datatable-column
      [width]="50"
      *ngIf="rowDetailTemplate"
      [resizeable]="false"
      [sortable]="false"
      [draggable]="false"
      [canAutoResize]="false"
    >
      <ng-template
        let-row="row"
        let-expanded="expanded"
        ngx-datatable-cell-template
      >
        <a
          href="javascript:void(0)"
          [class.datatable-icon-right]="!expanded"
          [class.datatable-icon-down]="expanded"
          title="Expand/Collapse Row"
          (click)="!expanded ? expandRow(row) : collapseRow(row)"
        ></a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngIf="selectionType === 'checkbox'"
      [width]="30"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="false"
      [resizeable]="false"
      [checkboxable]="checkboxable"
      [headerCheckboxable]="headerCheckboxable"
    >
      <ng-template
        ngx-datatable-cell-template
        let-value="value"
        let-row="row"
        let-isSelected="isSelected"
        let-onCheckboxChangeFn="onCheckboxChangeFn"
      >
        <input
          class="row-checkbox"
          [class.disabled]="isDisabled(isSelected)"
          type="checkbox"
          [checked]="isSelected"
          [disabled]="isDisabled(isSelected)"
          (change)="onCheckboxChangeFn($event)"
        />
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      *ngFor="let column of templateColumns"
      [name]="column.name ? column.name : column.prop"
      [prop]="column.prop"
      [headerTemplate]="column.headerTemplate"
      [sortable]="true"
      [width]="column?.width"
      [cellTemplate]="getColumnTemplate(column.template)"
      [flexGrow]="column.flexGrow"
    ></ngx-datatable-column>

    <ngx-datatable-column
      *ngFor="let column of columns"
      [name]="column.name ? column.name : column.prop"
      [prop]="column.prop"
      [sortable]="true"
      [comparator]="column?.comparator"
      [width]="column?.width"
      [cellTemplate]="getColumnTemplate(column?.template)"
      [headerTemplate]="column.headerTemplate"
      [flexGrow]="column.flexGrow"
    >
      <ng-template
        ngx-datatable-cell-template
        let-rowIndex="rowIndex"
        let-value="value"
        let-row="row"
      >
        <span *ngIf="!showEditForm || editing?.ID !== row?.ID || editing?.Hidden_ID !== row?.Hidden_ID; else editor">
          <span *ngIf="isColumnConfigCheckbox(column); else showValue">
            <mat-checkbox
              [disabled]="true"
              [checked]="value"
            ></mat-checkbox>
          </span>
        </span>

        <ng-template #showValue>
          <span *ngIf="column.name.includes('Date') && value; else nonDateValue">
            {{ value | displayDate }}
          </span>

          <ng-template #nonDateValue>
            <div class="ellipsis">{{ column.hidden ? '***' : value }}</div>
          </ng-template>
        </ng-template>

        <ng-template #editor>
          <app-data-grid-editor
            (valueUpdated)="updateEditProp($event)"
            [config]="getColumnConfig(column)"
            [column]="column"
            [row]="row"
            [value]="value"
          ></app-data-grid-editor>
        </ng-template>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <ng-template
    #hdrTpl
    let-column="column"
    let-sort="sortFn"
    let-sortDir="sortDir"
  >
    <div
      *ngIf="!showAddForm"
      style="cursor: pointer"
      (click)="sort($event, sortDir, sortFn)"
    >
      {{ translatePath + '.' + column.name | translate }}
      <i
        class="fas"
        [class.fa-sort-down]="sortDir === 'desc'"
        [class.fa-sort-up]="sortDir === 'asc'"
      ></i>
    </div>
    <!-- Column Filter Inputs -->
    <input
      *ngIf="showFilters"
      type="text"
      class="form-control"
      matInput
      [value]="filters[column.prop] ? filters[column.prop] : ''"
      style="padding: 8px; margin: 5px auto; width: 100%"
      placeholder="Filter {{ translatePath + '.' + column.name | translate }}"
      (select)="selectStopPropagation($event)"
      (keyup)="updateFilter($event.target.value, column.prop)"
    />

    <!-- Add Controls -->
    <ng-container *ngIf="showAddForm && columnsContainEditConfig">
      <app-data-grid-editor
        (valueUpdated)="updateAddItem($event)"
        [config]="getColumnConfig(column)"
        [column]="column"
        action="add"
      ></app-data-grid-editor>
    </ng-container>

    <!-- TODO: Determin if this can be replaced with data-grid-editor component -->
    <div *ngIf="showAddForm && CRUDConfig?.create?.columnConfig !== undefined">
      <!-- Default Text Input -->
      <mat-form-field
        *ngIf="
          CRUDConfig?.create?.columnConfig[column.prop]?.controlType === 'text' ||
          CRUDConfig?.create?.columnConfig[column.prop] === undefined ||
          CRUDConfig?.create?.columnConfig[column.prop].uneditable
        "
      >
        <input
          type="text"
          matInput
          class="form-control"
          [disabled]="CRUDConfig?.create?.columnConfig[column.prop]?.uneditable"
          [value]="newItem[column.prop] ? newItem[column.prop] : ''"
          style="padding: 4px; margin: 2px auto; width: 100%"
          placeholder="Enter {{ translatePath + '.' + column.name | translate }}"
          (keyup)="updateNewItem($event.target.value, column.prop)"
        />
      </mat-form-field>
      <!-- Check Box Input  -->
      <div
        *ngIf="CRUDConfig?.create?.columnConfig[column.prop]?.controlType === 'checkbox'"
        class="pt-3 mt-1"
      >
        <mat-checkbox (change)="updateNewItem($event.checked, column.prop)">
          {{ translatePath + '.' + column.name | translate }}
        </mat-checkbox>
      </div>
      <!-- Combobox Input -->
      <mat-form-field *ngIf="CRUDConfig?.create?.columnConfig[column.prop]?.controlType === 'combobox'">
        <input
          type="text"
          matInput
          class="form-control"
          [value]="newItem[column.prop] ? newItem[column.prop] : ''"
          style="padding: 4px; margin: 2px auto; width: 100%"
          placeholder="Enter {{ translatePath + '.' + column.name | translate }}"
          (keyup)="updateNewItem($event.target.value, column.prop)"
          [matAutocomplete]="auto"
        />

        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="updateNewItem($event.option.value, column.prop)"
        >
          <mat-option
            *ngFor="let option of CRUDConfig?.create?.columnConfig[column.prop].options | async"
            [value]="option[CRUDConfig?.create?.columnConfig[column.prop].referenceProp]"
          >
            {{ option[CRUDConfig?.create?.columnConfig[column.prop].referenceProp] }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- Select Input -->
      <mat-form-field *ngIf="CRUDConfig?.create?.columnConfig[column.prop]?.controlType === 'select'">
        <mat-select
          style="padding: 4px; margin: 2px auto; width: 100%"
          (selectionChange)="updateNewItem($event.value, column.prop)"
          placeholder="Select {{ translatePath + '.' + column.name | translate }}"
        >
          <mat-option
            *ngFor="let option of CRUDConfig?.create?.columnConfig[column.prop].options | async"
            [value]="option[CRUDConfig?.create?.columnConfig[column.prop].referenceProp]"
          >
            {{ option[CRUDConfig?.create?.columnConfig[column.prop].referenceProp] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template
    #selectHeader
    let-column="column"
    let-sort="sortFn"
    let-sortDir="sortDir"
  >
    <div
      style="cursor: pointer"
      (click)="sort($event, sortDir, sortFn)"
    >
      {{ translatePath + '.' + column.name | translate }}
      <i
        *ngIf="sortDir === 'desc'"
        class="fas fa-sort-down"
      ></i>
      <i
        *ngIf="sortDir === 'asc'"
        class="fas fa-sort-up"
      ></i>
    </div>
    <mat-form-field *ngIf="showFilters">
      <mat-select
        *ngIf="column.prop !== 'IsMasterList'"
        [value]="activeSelectFilters[column.prop]"
        multiple
        (selectionChange)="getFilterCriteria($event, column.prop)"
      >
        <mat-option
          *ngFor="let option of selectFilterOptions[column.prop]"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-select>
      <mat-select
        *ngIf="column.prop === 'IsMasterList'"
        multiple
        (selectionChange)="getFilterCriteria($event, column.prop)"
      >
        <mat-option
          *ngFor="let option of selectFilterOptions[column.prop]"
          [value]="option"
        >
          {{ option ? 'Yes' : 'No' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>

  <ng-template
    #multiSelectHeader
    let-column="column"
    let-sort="sortFn"
    let-sortDir="sortDir"
  >
    <div
      style="cursor: pointer"
      (click)="sort($event, sortDir, sortFn)"
    >
      {{ translatePath + '.' + column.name | translate }}
      <i
        *ngIf="sortDir === 'desc'"
        class="fas fa-sort-down"
      ></i>
      <i
        *ngIf="sortDir === 'asc'"
        class="fas fa-sort-up"
      ></i>
    </div>
    <mat-form-field *ngIf="showFilters">
      <mat-select
        multiple
        (selectionChange)="getMultiSelectFilterCriteria($event, column.prop)"
      >
        <mat-option
          *ngFor="let option of selectFilterOptions[column.prop]"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</div>

<app-datagrid-reorder-overlay
  *ngIf="reordering"
  (orderChange)="reorderDrop($event)"
  [tableName]="title"
  [reorderConfig]="FAReorderConfig"
  [reorderType]="selectedReorderType"
  [table]="myTable"
  [rows]="rows"
  [displayColumns]="columns"
  [height]="pageSize * 30 + 115"
></app-datagrid-reorder-overlay>

<app-context-menu
  (addEvent)="addItem()"
  (deleteEvent)="deleteItem()"
  (editEvent)="editItem()"
  [isShow]="contextmenu && selectedRow && !(showAddForm || showEditForm || showFilters)"
  [CRUDConfig]="CRUDConfig"
  [data]="contextmenuData"
  [x]="contextmenuX"
  [y]="contextmenuY"
></app-context-menu>

<!-- Table Column Cell Templates -->

<ng-template
  #propCheckboxTmpl
  let-column="column"
  let-row="row"
  let-rowIndex="rowIndex"
  let-value="value"
>
  <input
    class="prop-checkbox"
    type="checkbox"
    [checked]="row[column.prop]"
    (change)="togglePropCheckbox(row, column.prop, rowIndex)"
  />
</ng-template>
