<div
  class="contextmenu"
  [ngStyle]="{ 'left.px': x, 'top.px': y }"
  [ngClass]="{ 'd-none': !isShow }"
>
  <a
    *ngIf="isFunctionEnabled('add')"
    class="dropdown-item context-menu"
    (mousedown)="create($event)"
  >
    New
  </a>
  <a
    *ngIf="isFunctionEnabled('edit')"
    class="dropdown-item context-menu"
    (mousedown)="edit($event)"
  >
    Edit
  </a>
  <a
    *ngIf="isFunctionEnabled('delete')"
    class="dropdown-item context-menu"
    (mousedown)="delete($event)"
  >
    Delete
  </a>
  <button
    *ngFor="let action of data?.customActions"
    class="dropdown-item context-menu"
    [class.disabled]="action.disabled"
    [disabled]="action.disabled"
    (mousedown)="customAction($event, action)"
  >
    {{ action.name }}
  </button>
</div>
