
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Permission } from '~permissions/models/permission.model';

@Injectable({
  providedIn: 'root'
})
export class SecuritySettingsAppDataService {

  constructor(private http: HttpClient) { }

  getSecuritySettingsForUser(userID: number, securityGroupParentID: number): Observable<Permission[]> {
    const params = {
      userID,
      securityGroupParentID 
    };
    return this.http.get<Permission[]>(`securitysetting/getsecuritysettingsforuser`, { params: params as any });
  }
}
