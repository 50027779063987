<div class="flex flex-row flex-nowrap justify-center items-start my-8 flex-shrink min-h-0 w-full">
  <mat-card
    *ngIf="announcements.length"
    id="announcement"
    (mouseenter)="onMouseEnter($event)"
    (mouseleave)="onMouseLeave($event)"
  >
    <mat-card-header
      class="flex flex-row w-full justify-between items-center"
      [class.pb-2]="showContent"
    >
      <mat-card-title class="text-primary !mb-0">{{ dashlet ? dashlet.Name : 'Announcements' }}</mat-card-title>

      <span *ngIf="dashlet">
        <button
          mat-icon-button
          color="primary"
          [matTooltip]="showContent ? 'Minimize' : 'Expand'"
          (click)="toggleContent()"
        >
          <mat-icon>{{ showContent ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Options"
          [matMenuTriggerFor]="dashletOptions"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #dashletOptions>
          <button
            *ngIf="dashletPermissions.delete"
            mat-menu-item
            (click)="deleteDashlet()"
          >
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </span>
    </mat-card-header>

    <mat-card-content
      [@openClose]="showContent"
      class="flex flex-row justify-start items-start h-full w-full"
    >
      <ng-container *ngIf="showContent">
        <mat-card
          [@openClose]="showContent"
          *ngIf="currentAnnouncement; else noAnnouncements"
          id="announcement-card"
          class="h-full w-full"
        >
          <mat-card-header class="center-start w-full">
            <mat-card-title>
              <u>{{ currentAnnouncement?.Name }}</u>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="center-start h-full w-full">
            <quill-view-html [content]="currentAnnouncement?.Text"></quill-view-html>
          </mat-card-content>
        </mat-card>
        <ng-template #noAnnouncements>
          <p>No Announcements</p>
        </ng-template>
      </ng-container>
    </mat-card-content>

    <mat-card-actions [@openClose]="showContent">
      <div class="flex flex-row flex-nowrap justify-center items-center m-0 pb-3 h-12 w-full">
        <button
          mat-icon-button
          color="primary"
          class="mr-2"
          (click)="onPreviousClick()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>

        <mat-icon
          *ngFor="let announcement of announcements"
          class="text-sm pl-[1px] pb-[2px] !h-4 !w-4"
        >
          {{ currentAnnouncement === announcement ? 'lens' : 'panorama_fish_eye' }}
        </mat-icon>
        <button
          mat-icon-button
          color="primary"
          class="ml-2"
          (click)="onNextClick()"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
