<div
  class="reorder-overlay"
  [ngStyle]="{ 'left.px': x, 'top.px': y, 'width.px': width, 'height.px': height }"
>
  <div
    cdkDropList
    class="example-list"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      [ngStyle]="{ 'max-width.px': width }"
      *ngFor="let row of rows"
      class="example-box flex flex-row flex-nowrap gap-[15px] justify-start items-start w-full bg-white border-top border-bottom"
      cdkDrag
    >
      <div
        *ngFor="let col of displayColumns; let i = index"
        [tooltip]="row[col.prop]"
        class="flex flex-row justify-start items-start grow w-full"
        style="overflow: hidden"
      >
        <mat-checkbox
          *ngIf="isCheckbox(row, col); else textDisplay"
          [checked]="row[col.prop]"
          disabled="true"
        ></mat-checkbox>
        <ng-template #textDisplay>{{ row[col.prop] }}</ng-template>
      </div>
    </div>
  </div>
</div>
